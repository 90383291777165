<script>
import Loader from "../../components/xray/Loader";
import ApiService from "../../Utils/axios";
import VueHtml2pdf from "vue-html2pdf";
import { htmlToPaper } from "vue-html-to-paper";
import ApexChartPie from "./ApexCharts";

export default {
  components: { Loader, htmlToPaper, VueHtml2pdf, ApexChartPie },
  htmlToPdfOptions: {
    html2canvas: {
      scale: 1,
      useCORS: true,
    },
  },
  data() {
    return {
      survey: {},
      ass_user: {},
      bodyData: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [
          this.$t("satisfactory"),
          this.$t("unsatisfactory"),
          this.$t("unknown"),
          this.$t("noAnswer"),
        ],

        colors: ["#0084ff", "#00ca00", "#e64141", "#ffd400", "#00d0ff"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      done: false,
      chapters: null,
      answers: {},
      pdfLoader: false,
      displayUpBtn: false,
      bodyData1: {
        chart: {
          height: 130,
          width: 600,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            data: [23, 15],
          },
        ],
        colors: ["#2f48c5", "#ff7700", "#A9A9A9", "#000"],
        xaxis: {
          categories: [
            this.$t("satisfactory"),
            this.$t("unsatisfactory"),
            this.$t("unknown"),
            this.$t("noAnswer"),
          ],
        },
      },
      bodyData2: {
        chart: {
          height: 130,
          width: 600,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            data: [23, 15],
          },
        ],
        colors: ["#2f48c5", "#ff7700", "#A9A9A9", "#000"],
        xaxis: {
          categories: [
            this.$t("satisfactory"),
            this.$t("unsatisfactory"),
            this.$t("unknown"),
            this.$t("noAnswer"),
          ],
        },
      },
      bodyData3: {
        chart: {
          height: 130,
          width: 600,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            data: [23, 15],
          },
        ],
        colors: ["#2f48c5", "#ff7700", "#A9A9A9", "#000"],
        xaxis: {
          categories: [
            this.$t("satisfactory"),
            this.$t("unsatisfactory"),
            this.$t("unknown"),
            this.$t("noAnswer"),
          ],
        },
      },
      bodyData4: {
        chart: {
          height: 130,
          width: 600,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            data: [23, 15],
          },
        ],
        colors: ["#2f48c5", "#ff7700", "#A9A9A9", "#000"],
        xaxis: {
          categories: [
            this.$t("satisfactory"),
            this.$t("unsatisfactory"),
            this.$t("unknown"),
            this.$t("noAnswer"),
          ],
        },
      },
      stats: {},
    };
  },
  beforeMount() {
    this.fetchResult();
    this.fetchAnswers();
  },
  mounted() {
    document.title = "Resultats de l'évaluation - Auditor";

    this.$bvModal.show("info-modal");
  },
  // updated() {
  //   if (this.done) {
  //     // setTimeout(this.generatePdf, 3000);
  //     // setTimeout(this.printResults, 3000);
  //     // this.printResults();
  //   }
  // },
  methods: {
    showFiles(critId) {
      if (this.answers[critId]) {
        return !this.answers[critId].files.length;
      } else {
        return true;
      }
    },
    fetchAnswers() {
      new ApiService()
        .get(`/api/assessment/${this.$route.params.id}`)
        .then((res) => {
          let obj = {};
          res.data.data.Answers.forEach((e) => {
            obj[e.criterion_id] = { answer: e.answer, files: e.files };
          });

          this.ass_user = res.data.data.user;
          this.survey = res.data.data.survey;
          this.answers = obj;
          console.log(res.data);
          this.stats = res.data.data.results;

          (this.bodyData.series = [
            this.stats.good_answers_count,
            this.stats.answers_count - this.stats.good_answers_count,
            this.stats.criteria_count - this.stats.answers_count,
          ]),
            (this.bodyData1.series[0].data = [
              this.stats.criteria_level_1_count != -1
                ? this.stats.good_answers_level_1_count
                : 0,
              this.stats.criteria_level_1_count != -1
                ? this.stats.answers_level_1_count -
                  this.stats.good_answers_level_1_count
                : 0,
              this.stats.criteria_level_1_count != -1
                ? this.stats.criteria_level_1_count -
                  this.stats.answers_level_1_count
                : 0,
            ]);
          this.bodyData2.series[0].data = [
            this.stats.criteria_level_2_count != -1
              ? this.stats.good_answers_level_2_count
              : 0,
            this.stats.criteria_level_2_count != -1
              ? this.stats.answers_level_2_count -
                this.stats.good_answers_level_2_count
              : 0,
            this.stats.criteria_level_2_count != -1
              ? this.stats.criteria_level_2_count -
                this.stats.answers_level_2_count
              : 0,
          ];
          this.bodyData3.series[0].data = [
            this.stats.criteria_level_3_count != -1
              ? this.stats.good_answers_level_3_count
              : 0,
            this.stats.criteria_level_3_count != -1
              ? this.stats.answers_level_3_count -
                this.stats.good_answers_level_3_count
              : 0,
            this.stats.criteria_level_3_count != -1
              ? this.stats.criteria_level_3_count -
                this.stats.answers_level_3_count
              : 0,
          ];
          this.bodyData4.series[0].data = [
            this.stats.criteria_level_4_count != -1
              ? this.stats.good_answers_level_4_count
              : 0,
            this.stats.criteria_level_4_count != -1
              ? this.stats.answers_level_4_count -
                this.stats.good_answers_level_4_count
              : 0,
            this.stats.criteria_level_4_count != -1
              ? this.stats.criteria_level_4_count -
                this.stats.answers_level_4_count
              : 0,
          ];

          this.done2 = true;
          //   setTimeout(this.generatePdf, 1000);
          // setTimeout(this.printResults, 3000);
        })
        .catch(() => {
          this.$router.push("/" + this.$i18n.locale + "/survey");
          this.$store.dispatch("Alert/activate", {
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
            type: "danger",
          });
        });
    },
    fetchResult() {
      new ApiService()
        .get("/api/assessment/" + this.$route.params.id + "/result")
        .then((res) => {
          this.chapters = res.data.chapters;
          this.done = true;
          // this.fetchAnswers();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async printResults() {
      //Pass the element id here
      await this.$htmlToPaper("printMe");
    },

    generatePdf() {
      //   await this.$htmlToPaper('printMe');
      this.pdfLoader = true;
      this.$refs.html2Pdf.generatePdf();
    },
    openModal(id) {
      this.$bvModal.show("modal-" + id);
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollFunction() {
      if (
        document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300
      ) {
        this.displayUpBtn = true;
      } else {
        this.displayUpBtn = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <!-- <b-modal id="info-modal" hide-header hide-footer>
      <h4 class="font-weight-bold" style="color: #ff7700">
        Impression en cours, veuillez patienter...
      </h4>
    </b-modal> -->
    <div class="d-flex justify-content-center mt-4">
      <h4><b> Aperçu avant impression</b>s</h4>
    </div>
    <div class="d-flex justify-content-center mt-4">
      <b-button variant="primary" @click="printResults"
        ><span> {{ $t("buttons.print") }}</span>
      </b-button>
    </div>
    <div id="printMe" style="margin: 100px">
      <div class="d-flex justify-content-md-center mt-5 mb-5">
        <div class="col-md-10 col-lg-10">
          <h4 class="text-center">
            Resultats de l'évaluation :
            {{
              survey.reference + " - " + survey.title + " : " + ass_user.name
            }}
          </h4>
        </div>
      </div>

      <div class="justify-content-center">
        <div class="col-md-8 col-lg-8" id="charts" v-if="done && done2">
          <AmChart
            color1="#32CE00"
            color2="#FF3333"
            color3="#A9A9A9"
            element="radial-1"
            type="radial-pie"
            :option="[
              {
                country: 'Satisfaits',
                value: stats.good_answers_count,
              },
              {
                country: $t('unsatisfactory'),
                value: stats.answers_count - stats.good_answers_count,
              },
              {
                country: this.$t('noAnswer'),
                value: stats.criteria_count - stats.answers_count,
              },
            ]"
            :height="350"
          />
          <hr />
        </div>
      </div>
      <div class="d-flex justify-content-md-center mt-5" v-if="done && done2">
        <div class="col-lg-10 col-md-10">
          <ApexChart element="bar-1" :chartOption="bodyData1" />
          <div class="d-flex justify-content-center">
            <strong>{{ $t("level") }}</strong>
            <span>
              <i class="fas fa-star star-gold star" style="color: gold" />
              <i class="fas fa-star star" style="color: Lightgrey" />
              <i class="fas fa-star star" style="color: Lightgrey" />
              <i class="fas fa-crown star" style="color: Lightgrey" />
            </span>
          </div>
          <div class="d-flex justify-content-center">
            <p style="font-size: 1em" class="text-danger">
              <strong>{{
                stats.critical_answers_level_1_count -
                stats.critical_good_answers_level_1_count
              }}</strong>
              Critère(s) critique(s) non satisfait(s)
            </p>
          </div>
          <!-- </div>
        <div class="col-lg-10 col-md-10"> -->
          <ApexChart element="bar-2" :chartOption="bodyData2" />
          <div class="d-flex justify-content-center">
            <strong> {{ $t("level") }}</strong
            ><span>
              <i class="fas fa-star star-gold star" style="color: gold" />
              <i class="fas fa-star star-gold star" style="color: gold" />
              <i class="fas fa-star star" style="color: Lightgrey" />
              <i class="fas fa-crown star" style="color: Lightgrey" />
            </span>
          </div>
          <div class="d-flex justify-content-center">
            <p style="font-size: 1em" class="text-danger">
              <strong>{{
                stats.critical_answers_level_2_count -
                stats.critical_good_answers_level_2_count
              }}</strong>
              Critère(s) critique(s) non satisfait(s)
            </p>
          </div>
          <!-- </div>
        <div class="col-lg-10 col-md-10"> -->
          <ApexChart element="bar-3" :chartOption="bodyData3" />
          <div class="d-flex justify-content-center">
            <strong>{{ $t("level") }}</strong
            ><span>
              <i class="fas fa-star star-gold star" style="color: gold" />
              <i class="fas fa-star star-gold star" style="color: gold" />
              <i class="fas fa-star star-gold star" style="color: gold" />
              <i class="fas fa-crown star" style="color: Lightgrey" />
            </span>
          </div>
          <div class="d-flex justify-content-center">
            <p style="font-size: 1em" class="text-danger">
              <strong>{{
                stats.critical_answers_level_3_count -
                stats.critical_good_answers_level_3_count
              }}</strong>
              Critère(s) critique(s) non satisfait(s)
            </p>
          </div>
          <!-- </div> -->
          <!-- <div class="col-lg-10 col-md-10"> -->
          <ApexChart element="bar-4" :chartOption="bodyData4" />
          <div class="d-flex justify-content-center">
            <strong>{{ $t("level") }}</strong
            ><span>
              <i class="fas fa-star star-gold star" style="color: gold" />
              <i class="fas fa-star star-gold star" style="color: gold" />
              <i class="fas fa-star star-gold star" style="color: gold" />
              <i class="fas fa-crown star-gold star" style="color: gold" />
            </span>
          </div>
          <div class="d-flex justify-content-center">
            <p style="font-size: 1em" class="text-danger">
              <strong>{{
                stats.critical_answers_level_4_count -
                stats.critical_good_answers_level_4_count
              }}</strong>
              Critère(s) critique(s) non satisfait(s)
            </p>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-md-center mt-5">
        <div class="col-lg-10 col-md-10">
          <b-alert
            v-if="
              stats.critical_answers_count > stats.critical_good_answers_count
            "
            show
            variant="danger"
            style="justify-content: center"
          >
            <h4 class="text-center text-danger">
              <strong>{{
                stats.critical_answers_count - stats.critical_good_answers_count
              }}</strong>
              critère(s) critique(s) non satisfait(s) sur un total de
              <strong>{{ stats.critical_answers_count }}</strong> et
              <strong>{{
                stats.critical_criteria_count - stats.critical_answers_count
              }}</strong>
              sans réponse(s)
            </h4>
          </b-alert>
        </div>
      </div>

      <div class="row justify-content-md-center">
        <div class="col-lg-10 col-md-10">
          <div v-for="chapter of chapters" :key="chapter.id">
            <h4 class="font-weight-bold" style="color: #ff7700">
              {{ chapter.reference + " " + chapter.title }}
            </h4>
            <div
              style="
                border-left: 2px solid rgb(187, 187, 187);
                margin-left: 10px;
              "
              v-for="section of chapter.sections"
              :key="section.id"
            >
              <h5 class="p-2 pt-4 text-primary">
                {{ section.refernce + " " + section.title }}
              </h5>
              <div
                class="p-3 crit pdf-item"
                v-for="(crit, i) of section.criteria"
                :key="crit.id"
              >
                <hr v-if="i >= 1" />
                <div v-if="!crit.subcriteria.length">
                  <p class="text-black">
                    {{ crit.refernce }} - {{ crit.question }}
                  </p>
                  <div class="text-center mt-3">
                    <div v-if="crit.critical" class="text-danger">
                      <i class="fas fa-exclamation-circle" />
                      {{ $t("critical") }}
                    </div>
                    <span v-if="crit.level == 4">
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-crown star-gold star"
                        style="color: gold"
                      />
                    </span>
                    <span v-if="crit.level == 3">
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i class="fas fa-crown star" style="color: Lightgrey" />
                    </span>
                    <span v-if="crit.level == 2">
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i class="fas fa-star star" style="color: Lightgrey" />
                      <i class="fas fa-crown star" style="color: Lightgrey" />
                    </span>
                    <span v-if="crit.level == 1">
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i class="fas fa-star star" style="color: Lightgrey" />
                      <i class="fas fa-star star" style="color: Lightgrey" />
                      <i class="fas fa-crown star" style="color: Lightgrey" />
                    </span>
                  </div>
                  <div class="text-center criteria-options">
                    <div style="font-size: medium" class="text-center mt-3">
                      <span v-if="answers[crit.id]"
                        ><span class="mr-2">{{ $t("answer") }}:</span>
                        <span
                          class="font-weight-bold text-success"
                          v-if="answers[crit.id].answer === 'yes'"
                        >
                          <i class="far fa-check-circle"></i>
                          {{ $t("buttons.yes") }}</span
                        >
                        <span
                          class="font-weight-bold text-danger"
                          v-else-if="answers[crit.id].answer === 'no'"
                        >
                          <i class="far fa-times-circle"></i>
                          {{ $t("buttons.no") }}</span
                        >
                        <span
                          class="font-weight-bold"
                          v-else-if="answers[crit.id].answer === 'unknown'"
                        >
                          {{ $t("buttons.unknown") }}</span
                        >
                        <span
                          class="font-weight-bold text-danger"
                          v-else-if="
                            answers[crit.id].answer == '3' ||
                            answers[crit.id].answer == '2' ||
                            answers[crit.id].answer == '1'
                          "
                        >
                          {{ answers[crit.id].answer }}</span
                        >
                        <span
                          class="font-weight-bold text-success"
                          v-else-if="answers[crit.id].answer == 4"
                        >
                          4</span
                        >
                      </span>
                      <span class="font-weight-bold text-danger" v-else>{{
                        $t("noAnswer")
                      }}</span>
                    </div>
                  </div>
                </div>
                <div v-else style="margin-left: 20px">
                  <p class="text-black">
                    {{ crit.refernce }} - {{ crit.question }}
                  </p>
                  <div
                    v-for="subcrit of crit.subcriteria"
                    :key="subcrit.id"
                    style="
                      padding: 30px;
                      border-left: 2px solid rgb(187, 187, 187);
                    "
                  >
                    <p class="text-black">
                      {{ subcrit.refernce }} -
                      {{ subcrit.question }}
                    </p>
                    <div class="text-center criteria-options">
                      <div style="font-size: medium" class="text-center mt-3">
                        <span v-if="answers[subcrit.id]"
                          ><span class="mr-2">{{ $t("answer") }}:</span>
                          <span
                            class="font-weight-bold text-success"
                            v-if="answers[subcrit.id].answer === 'yes'"
                          >
                            <i class="far fa-check-circle"></i>
                            {{ $t("buttons.yes") }}</span
                          >
                          <span
                            class="font-weight-bold text-danger"
                            v-else-if="answers[subcrit.id].answer === 'no'"
                          >
                            <i class="far fa-times-circle"></i>
                            {{ $t("buttons.no") }}</span
                          >
                          <span
                            class="font-weight-bold"
                            v-else-if="answers[subcrit.id].answer === 'unknown'"
                          >
                            {{ $t("buttons.unknown") }}</span
                          >
                          <span
                            class="font-weight-bold text-danger"
                            v-else-if="
                              answers[subcrit.id].answer == '3' ||
                              answers[subcrit.id].answer == '2' ||
                              answers[subcrit.id].answer == '1'
                            "
                          >
                            {{ answers[subcrit.id].answer }}</span
                          >
                          <span
                            class="font-weight-bold text-success"
                            v-else-if="answers[subcrit.id].answer == 4"
                          >
                            4</span
                          >
                        </span>
                        <span class="font-weight-bold text-danger" v-else>{{
                          $t("noAnswer")
                        }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="p-3 crit-outside pdf-item"
              v-for="(crit, i) of chapter.criteria"
              :key="crit.id"
            >
              <hr v-if="i >= 1" />
              <div class="" v-if="!crit.subcriteria.length">
                <div class="" style="font-size: medium">
                  <p class="text-black">
                    {{ crit.refernce }} - {{ crit.question }}
                  </p>
                </div>
                <div class="text-center criteria-options">
                  <div v-if="crit.critical" class="text-danger">
                    <i class="fas fa-exclamation-circle" /> {{ $t("critical") }}
                  </div>
                  <div class="text-center mt-3">
                    <span v-if="crit.level == 4">
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />

                      <i
                        class="fas fa-crown star-gold star"
                        style="color: gold"
                      />
                    </span>
                    <span v-if="crit.level == 3">
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />

                      <i class="fas fa-crown star" style="color: Lightgrey" />
                    </span>
                    <span v-if="crit.level == 2">
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i class="fas fa-star star" style="color: Lightgrey" />
                      <i class="fas fa-crown star" style="color: Lightgrey" />
                    </span>
                    <span v-if="crit.level == 1">
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i class="fas fa-star star" style="color: Lightgrey" />
                      <i class="fas fa-star star" style="color: Lightgrey" />
                      <i class="fas fa-crown star" style="color: Lightgrey" />
                    </span>
                  </div>
                  <div style="font-size: medium" class="text-center mt-3">
                    <span v-if="answers[crit.id]"
                      ><span class="mr-2">{{ $t("answer") }}:</span>
                      <span
                        class="font-weight-bold text-success"
                        v-if="answers[crit.id].answer === 'yes'"
                      >
                        <i class="far fa-check-circle"></i>
                        {{ $t("buttons.yes") }}</span
                      >
                      <span
                        class="font-weight-bold text-danger"
                        v-else-if="answers[crit.id].answer === 'no'"
                      >
                        <i class="far fa-times-circle"></i>
                        {{ $t("buttons.no") }}</span
                      >
                      <span
                        class="font-weight-bold"
                        v-else-if="answers[crit.id].answer === 'unknown'"
                      >
                        {{ $t("buttons.unknown") }}</span
                      >
                      <span
                        class="font-weight-bold text-danger"
                        v-else-if="
                          answers[crit.id].answer == '3' ||
                          answers[crit.id].answer == '2' ||
                          answers[crit.id].answer == '1'
                        "
                      >
                        {{ answers[crit.id].answer }}</span
                      >
                      <span
                        class="font-weight-bold text-success"
                        v-else-if="answers[crit.id].answer == '4'"
                        >4</span
                      >
                    </span>
                    <span class="font-weight-bold text-danger" v-else>{{
                      $t("noAnswer")
                    }}</span>
                  </div>
                </div>
              </div>
              <div v-else style="margin-left: 20px">
                <div style="font-size: medium">
                  <div class="">
                    <p class="text-black">
                      {{ crit.refernce }} - {{ crit.question }}
                    </p>
                    <div v-if="crit.critical" class="text-danger">
                      <i class="fas fa-exclamation-circle" />
                      {{ $t("critical") }}
                    </div>
                    <span v-if="crit.level == 4">
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-crown star-gold star"
                        style="color: gold"
                      />
                    </span>
                    <span v-if="crit.level == 3">
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />

                      <i class="fas fa-crown star" style="color: Lightgrey" />
                    </span>
                    <span v-if="crit.level == 2">
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i class="fas fa-star star" style="color: Lightgrey" />
                      <i class="fas fa-crown star" style="color: Lightgrey" />
                    </span>
                    <span v-if="crit.level == 1">
                      <i
                        class="fas fa-star star-gold star"
                        style="color: gold"
                      />
                      <i class="fas fa-star star" style="color: Lightgrey" />
                      <i class="fas fa-star star" style="color: Lightgrey" />
                      <i class="fas fa-crown star" style="color: Lightgrey" />
                    </span>
                  </div>

                  <div
                    v-for="subcrit of crit.subcriteria"
                    :key="subcrit.id"
                    class="sub-crit"
                  >
                    <div class="col-lg-9">
                      <p class="text-black">
                        {{ subcrit.refernce }} - {{ subcrit.question }}
                      </p>
                    </div>
                    <div class="text-center criteria-options">
                      <div class="text-center mt-3">
                        <div style="font-size: medium" class="text-center mt-3">
                          <span v-if="answers[subcrit.id]"
                            ><span class="mr-2">{{ $t("answer") }}:</span>
                            <span
                              class="font-weight-bold text-success"
                              v-if="answers[subcrit.id].answer === 'yes'"
                            >
                              <i class="far fa-check-circle"></i
                              >{{ $t("buttons.yes") }}
                            </span>
                            <span
                              class="font-weight-bold text-danger"
                              v-else-if="answers[subcrit.id].answer === 'no'"
                            >
                              <i class="far fa-times-circle"></i
                              >{{ $t("buttons.no") }}
                            </span>
                            <span
                              class="font-weight-bold"
                              v-else-if="
                                answers[subcrit.id].answer === 'unknown'
                              "
                            >
                              {{ $t("buttons.unknown") }}
                            </span>
                            <span
                              class="font-weight-bold text-danger"
                              v-else-if="
                                answers[subcrit.id].answer == '3' ||
                                answers[subcrit.id].answer == '2' ||
                                answers[subcrit.id].answer == '1'
                              "
                            >
                              {{ answers[subcrit.id].answer }}
                            </span>
                            <span
                              class="font-weight-bold text-success"
                              v-else-if="answers[subcrit.id].answer == '4'"
                              >4
                            </span>
                          </span>
                          <span class="font-weight-bold text-danger" v-else>{{
                            $t("noAnswer")
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
