<template>
  <div>
    <Loader />
    <section class="sign-in-page">
      <b-container class="sign-in-page-bg mt-5 p-0">
        <div class="row no-gutters">
          <div class="col-md-6 text-center position-relative">
            <div class="sign-in-detail text-white">
              <!-- <img
                :src="require('../../assets/images/euromedi_logo.png')"
                style="width: 18rem"
                alt="euromedi-logo"
              /> -->
              <h1 class="mb-5 text-white">
                Audit<i style="font-size: 0.8em" class="fas fa-dharmachakra"></i
                >r
              </h1>
              <Slick :option="slickOptions">
                <!-- EHPAD MRS -->
                <!-- <div class="item">
                  <img
                    :src="require('../../assets/images/login/demo.png')"
                    class="img-fluid mb-4"
                    alt="logo"
                  />
                  <h4 class="mb-1 text-white">MR-MRS</h4>
                  <p>
                    Evaluer le niveau de qualité des services d'acceuil des
                    personnes agéés et des soins médicaux prersonnalisés.
                  </p>
                </div> -->

                <!-- CLINIQUES -->
                <div class="item">
                  <img
                    :src="require('../../assets/images/login/logo_clinics.png')"
                    class="img-fluid mb-4"
                    alt="logo"
                  />
                  <h4 class="mb-1 text-white"><!-- Cliniques -->Clinics</h4>
                  <p>
                    <!-- Evaluer le niveau de qualité, de sécurité et des services de
                    votre clinique. -->
                    Evaluate the level of quality, safety and services of your
                    clinic
                  </p>
                </div>

                <!-- CLINIQUES -->
                <!-- <div class="item">
                  <img
                    :src="require('../../assets/images/login/cli-dent.png')"
                    class="img-fluid mb-4"
                    alt="logo"
                  />
                  <h4 class="mb-1 text-white">Cliniques</h4>
                  <p>
                    Evaluer le niveau de qualité, de sécurité et des services de
                    votre clinique dentaire.
                  </p>
                </div> -->

                <!-- HOPITAUX ET CLINIQUES -->
                <!-- <div class="item">
                  <img
                    :src="require('../../assets/images/login/1.png')"
                    class="img-fluid mb-4"
                    alt="logo"
                  />
                  <h4 class="mb-1 text-white">Hôpitaux, Cliniques & Centres médicaux</h4>
                  <p>
                      Evaluer le niveau de qualité des services et répondre aux enjeux actuels d’exigence en termes de sécurité des soins...
                  </p>
                </div> -->

                <!-- CENTRES -->
                <!-- <div class="item">
                  <img
                    :src="require('../../assets/images/login/3.png')"
                    class="img-fluid mb-4"
                    alt="logo"
                  />
                  <h4 class="mb-1 text-white">Centres de formation, Simulations médicales & Software </h4>
                  <p>
                    Evaluer le niveau de qualité des centres de formation et des outils informatiques des établissements de soins.
                  </p>
                </div> -->
              </Slick>
            </div>
          </div>
          <div class="col-md-6 position-relative">
            <div class="sign-in-from">
              <router-view></router-view>
            </div>
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
import Loader from "../../components/xray/loader/Loader";
import logo from "../../assets/images/logo-white.png";
import { xray } from "../../config/pluginInit";
import bgImage from "../../assets/images/login/2.png";
export default {
  name: "AuthLayout1",
  components: {
    Loader,
  },
  mounted() {
    xray.index();
  },
  data() {
    return {
      slickOptions: {
        slidesToShow: 1,
        arrows: false,
        dots: true,
      },
      logo: logo,
      bgImageURL: bgImage,
    };
  },
};
</script>
