<script>
export default {
  props: {
    data: Object,
    hide: Boolean,
  },
  data: () => ({}),
};
</script>

<template>
  <div class="d-flex" style="margin-left: 20px" v-if="!data.subcriteria.length">
    <!-- // ------------------------- NO SUB CRIT --------------------------- // -->
    <div class="col-10 col-md-10" style="font-size: medium">
      <p class="text-black">
        <i
          ><b>{{ data.refernce }}</b></i
        >
        - <b>{{ data.question }}</b>
      </p>
      <div
        style="border: 2px solid #ff7700; border-radius: 10px"
        class="p-3"
        v-if="data.requirements"
      >
        <span class="font-weight-bold" style="color: #ff7700">{{
          $t("requirements")
        }}</span>
        <nl2br tag="p" :text="data.requirements" class-name="pt-2 pl-3" />
      </div>
      <div
        v-if="data.explanations"
        style="border: 2px solid #2f48c5; border-radius: 10px"
        class="p-3 mt-3"
      >
        <span class="text-primary font-weight-bold">
          {{ $t("explanations") }}</span
        >
        <nl2br tag="p" :text="data.explanations" class-name="pt-2 pl-3" />
      </div>
    </div>
    <div class="col-lg-2 col-md-2 text-center criteria-options">
      <div v-if="data.critical" class="text-danger">
        <i class="fas fa-exclamation-circle" /> {{ $t("critical") }}
      </div>
      <div v-if="!hide" class="text-center mt-3">
        <span v-if="data.level == 4">
          <i class="fas fa-star star-gold star" style="color: gold" />
          <i class="fas fa-star star-gold star" style="color: gold" />
          <i class="fas fa-star star-gold star" style="color: gold" />

          <i class="fas fa-crown star-gold star" style="color: gold" />
        </span>
        <span v-if="data.level == 3">
          <i class="fas fa-star star-gold star" style="color: gold" />
          <i class="fas fa-star star-gold star" style="color: gold" />
          <i class="fas fa-star star-gold star" style="color: gold" />

          <i class="fas fa-crown star" style="color: Lightgrey" />
        </span>
        <span v-if="data.level == 2">
          <i class="fas fa-star star-gold star" style="color: gold" />
          <i class="fas fa-star star-gold star" style="color: gold" />
          <i class="fas fa-star star" style="color: Lightgrey" />

          <i class="fas fa-crown star" style="color: Lightgrey" />
        </span>
        <span v-if="data.level == 1">
          <i class="fas fa-star star-gold star" style="color: gold" />
          <i class="fas fa-star star" style="color: Lightgrey" />
          <i class="fas fa-star star" style="color: Lightgrey" />

          <i class="fas fa-crown star" style="color: Lightgrey" />
        </span>
      </div>
      <div v-if="data.require_files && !hide" class="text-primary mt-3">
        <i class="fas fa-file-text"></i> {{ $t("docRequired") }}
      </div>
      <div v-if="data.optional">{{ $t("optional") }}</div>

      <div v-if="!hide" class="mt-3">
        <p v-if="data.answer === 'yesno'">
          <b>{{ $t("yesNo") }}</b>
        </p>
        <p v-else-if="data.answer === 'range'">
          <b>{{ $t("oneFour") }}</b>
        </p>
        <p v-else>{{ $t("error") }}</p>
      </div>
    </div>
  </div>

  <!-- // ------------------------- SUB CRIT --------------------------- // -->
  <div v-else style="margin-left: 10px">
    <div style="font-size: medium">
      <div class="d-flex">
        <div class="col-10">
          <p class="text-black">
            <i
              ><b>{{ data.refernce }}</b></i
            >
            - <b>{{ data.question }}</b>
          </p>
          <div
            style="border: 2px solid #ff7700; border-radius: 10px"
            class="p-3"
            v-if="data.requirements"
          >
            <span class="font-weight-bold" style="color: #ff7700">
              {{ $t("requirements") }}</span
            >
            <nl2br tag="p" :text="data.requirements" class-name="pt-2 pl-3" />
          </div>
          <div
            v-if="data.explanations"
            style="border: 2px solid #2f48c5; border-radius: 10px"
            class="p-3 mt-3"
          >
            <span class="text-primary font-weight-bold">
              {{ $t("explanations") }}</span
            >
            <nl2br tag="p" :text="data.explanations" class-name="pt-2 pl-3" />
          </div>
        </div>
        <div v-if="!hide" class="text-center col-2">
          <span v-if="data.level == 4">
            <i class="fas fa-star star-gold star" style="color: gold" />
            <i class="fas fa-star star-gold star" style="color: gold" />
            <i class="fas fa-star star-gold star" style="color: gold" />
            <i class="fas fa-crown star-gold star" style="color: gold" />
          </span>
          <span v-if="data.level == 3">
            <i class="fas fa-star star-gold star" style="color: gold" />
            <i class="fas fa-star star-gold star" style="color: gold" />
            <i class="fas fa-star star-gold star" style="color: gold" />
            <i class="fas fa-crown star" style="color: Lightgrey" />
          </span>
          <span v-if="data.level == 2">
            <i class="fas fa-star star-gold star" style="color: gold" />
            <i class="fas fa-star star-gold star" style="color: gold" />
            <i class="fas fa-star star" style="color: Lightgrey" />
            <i class="fas fa-crown star" style="color: Lightgrey" />
          </span>
          <span v-if="data.level == 1">
            <i class="fas fa-star star-gold star" style="color: gold" />
            <i class="fas fa-star star" style="color: Lightgrey" />
            <i class="fas fa-star star" style="color: Lightgrey" />
            <i class="fas fa-crown star" style="color: Lightgrey" />
          </span>
        </div>
      </div>
      <!-- BOUCLE SUR LES SOUS-CRITERES -->
      <div
        v-for="subcrit of data.subcriteria"
        :key="subcrit.id"
        style="margin-left: 35px; padding: 15px"
        class="d-flex sub-crit"
      >
        <div class="col-lg-10 col-md-10">
          <p class="text-black">
            <i
              ><b>{{ subcrit.refernce }}</b></i
            >
            - <b class="text-black">{{ subcrit.question }}</b>
          </p>
          <div
            style="border: 2px solid #ff7700; border-radius: 10px"
            v-if="subcrit.requirements"
          >
            <span class="font-weight-bold ml-2" style="color: #ff7700">
              {{ $t("requirements") }}</span
            >
            <nl2br
              tag="p"
              :text="subcrit.requirements"
              class-name="pt-2 pl-3"
            />
          </div>
          <div
            v-if="subcrit.explanations"
            style="border: 2px solid #2f48c5; border-radius: 10px"
            class="mt-3"
          >
            <span class="text-primary font-weight-bold ml-2">
              {{ $t("explanations") }}</span
            >
            <nl2br
              tag="p"
              :text="subcrit.explanations"
              class-name="pt-2 pl-3"
            />
          </div>
        </div>
        <div class="col-lg-2 col-md-2 text-center criteria-options">
          <div v-if="subcrit.critical" class="text-danger">
            <i class="fas fa-exclamation-circle" />
            {{ $t("critical") }}
          </div>
          <div v-if="subcrit.require_files && !hide" class="text-primary">
            <i class="fas fa-file-text"></i> {{ $t("docRequired") }}
          </div>

          <div v-if="subcrit.optional && !hide">{{ $t("optional") }}</div>

          <div v-if="!hide" class="mt-3">
            <p v-if="subcrit.answer === 'yesno'">
              <b>{{ $t("yesNo") }}</b>
            </p>
            <p v-else-if="subcrit.answer === 'range'">
              <b>{{ $t("oneFour") }}</b>
            </p>
            <p v-else>{{ $t("error") }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style></style>
