<script>
import Loader from "../../components/xray/Loader";
import ApiService from "../../Utils/axios";
// import VueHtml2pdf from "vue-html2pdf";
import { mapGetters, mapActions } from "vuex";
import ApexChartPie from "./ApexCharts";
import { htmlToPaper } from "vue-html-to-paper";
import excel from "vue-excel-export";

export default {
  components: { Loader, ApexChartPie },
  computed: {
    ...mapGetters({
      user: "Auth/getCurrentUser",
    }),
  },
  data() {
    return {
      json_fields: {
        Chapitre: "chapter.title",
        Section: "section.title",
        Critère: "crit.question",
        "Sous critère": "subcrit.question",
        Réponse: "answer",
        Niveau: "crit.level",
        Critique: {
          field: "crit.critical",
          callback: (v) => (v ? "Oui" : "Non"),
        },
        "Piéces jointes": "files",
      },
      jstats: {},
      survey: {},
      ass_user: {},
      bodyData: {
        chart: {
          width: 380,
          type: "pie",
        },
        labels: [
          this.$t("satisfactory"),
          this.$t("unsatisfactory"),
          this.$t("unknown"),
          this.$t("noAnswer"),
        ],

        colors: ["#0084ff", "#00ca00", "#e64141", "#ffd300", "#00d0ff"],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
      apiurl: process.env.VUE_APP_API,
      done: false,
      chapters: null,
      answers: {},
      pdfLoader: false,
      displayUpBtn: false,
      bodyData1: {
        chart: {
          height: 150,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            data: [23, 15],
          },
        ],
        colors: ["#2f48c5", "#ff7700", "#A9A9A9", "#000"],
        xaxis: {
          categories: [
            this.$t("satisfactory"),
            this.$t("unsatisfactory"),
            this.$t("unknown"),
            this.$t("noAnswer"),
          ],
        },
      },
      bodyData2: {
        chart: {
          height: 150,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            data: [23, 15],
          },
        ],
        colors: ["#2f48c5", "#ff7700", "#A9A9A9", "#000"],
        xaxis: {
          categories: [
            this.$t("satisfactory"),
            this.$t("unsatisfactory"),
            this.$t("unknown"),
            this.$t("noAnswer"),
          ],
        },
      },
      bodyData3: {
        chart: {
          height: 150,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            data: [23, 15],
          },
        ],
        colors: ["#2f48c5", "#ff7700", "#A9A9A9", "#000"],
        xaxis: {
          categories: [
            this.$t("satisfactory"),
            this.$t("unsatisfactory"),
            this.$t("unknown"),
            this.$t("noAnswer"),
          ],
        },
      },
      bodyData4: {
        chart: {
          height: 150,
          type: "bar",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
            distributed: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        series: [
          {
            data: [23, 15],
          },
        ],
        colors: ["#2f48c5", "#ff7700", "#A9A9A9", "#000"],
        xaxis: {
          categories: [
            this.$t("satisfactory"),
            this.$t("unsatisfactory"),
            this.$t("unknown"),
            this.$t("noAnswer"),
          ],
        },
      },
      done2: false,
      done3: false,
    };
  },
  beforeMount() {
    this.fetchResult();
    this.fetchAnswers();
  },
  mounted() {
    document.title = "Resultats de l'évaluation - Auditor";
    window.onscroll = () => {
      this.scrollFunction();
    };
  },
  updated() {
    if (this.done && this.done2) {
      const answers = this.answers;
      let stats = {
        criteria_count: 0,
        answers_count: 0,
        good_answers_count: 0,
        unknown_answers_count: 0,
        critical_criteria_count: 0,
        critical_answers_count: 0,
        critical_good_answers_count: 0,
        criteria_level_1_count: 0,
        criteria_level_2_count: 0,
        criteria_level_3_count: 0,
        criteria_level_4_count: 0,
        answers_level_1_count: 0,
        answers_level_2_count: 0,
        answers_level_3_count: 0,
        answers_level_4_count: 0,
        good_answers_level_1_count: 0,
        good_answers_level_2_count: 0,
        good_answers_level_3_count: 0,
        good_answers_level_4_count: 0,
        unknown_answers_level_1_count: 0,
        unknown_answers_level_2_count: 0,
        unknown_answers_level_3_count: 0,
        unknown_answers_level_4_count: 0,
        critical_criteria_level_1_count: 0,
        critical_criteria_level_2_count: 0,
        critical_criteria_level_3_count: 0,
        critical_criteria_level_4_count: 0,
        critical_answers_level_1_count: 0,
        critical_answers_level_2_count: 0,
        critical_answers_level_3_count: 0,
        critical_answers_level_4_count: 0,
        critical_good_answers_level_1_count: 0,
        critical_good_answers_level_2_count: 0,
        critical_good_answers_level_3_count: 0,
        critical_good_answers_level_4_count: 0,
      };

      const calculateStats = (crit, parent) => {
        stats[`criteria_level_${crit.level}_count`] += 1;
        stats.criteria_count += 1;
        if (crit.critical) {
          stats[`critical_criteria_level_${crit.level}_count`] += 1;
          stats.critical_criteria_count += 1;
        }
        if (answers[crit.id]) {
          stats.answers_count += 1;
          stats[`answers_level_${crit.level}_count`] += 1;
          if (
            (answers[crit.id].answer > 3 || answers[crit.id].answer == "yes") &&
            !parent
          ) {
            stats[`good_answers_level_${crit.level}_count`] += 1;
            stats.good_answers_count += 1;

            if (crit.critical) {
              stats[`critical_good_answers_level_${crit.level}_count`] += 1;
              stats.critical_good_answers_count += 1;
            }
          }
          if (answers[crit.id].answer == "unknown") {
            stats[`unknown_answers_level_${crit.level}_count`] += 1;
            stats.unknown_answers_count += 1;
          }
          if (crit.critical) {
            stats[`critical_answers_level_${crit.level}_count`] += 1;
            stats.critical_answers_count += 1;
          }
        }

        if (parent) {
          if (crit.critical && answers[crit.subcriteria[0].id]) {
            stats.critical_answers_count += 1;
            stats[`critical_answers_level_${crit.level}_count`] += 1;
          }
          if (answers[crit.subcriteria[0].id]) {
            stats.answers_count += 1;
            if (
              answers[crit.subcriteria[0].id].answer > 3 ||
              answers[crit.subcriteria[0].id].answer == "yes"
            ) {
              if (crit.critical) {
                // stats[`critical_good_answers_level_${crit.level}_count`] += 1;
                stats.critical_good_answers_count += 1;
              }
            }
          }
        }
      };

      let assessmentAnswer = [];

      this.chapters.forEach((c) => {
        if (c.criteria.length) {
          // critere dans chapitre
          c.criteria.forEach((crit) => {
            calculateStats(crit, crit.subcriteria.length);
            if (crit.subcriteria.length) {
              let isParentGood = true;
              if (answers[crit.subcriteria[0].id]) {
                stats[`answers_level_${crit.level}_count`] += 1;
              }
              crit.subcriteria.forEach((sub) => {
                assessmentAnswer.push({
                  chapter: c,
                  section: null,
                  crit: crit,
                  subcrit: sub,
                  answer:
                    answers[sub.id] && answers[sub.id].answer
                      ? answers[sub.id].answer
                      : "",
                  files:
                    answers[sub.id] && answers[sub.id].files.length
                      ? "Oui"
                      : "Non",
                });
                if (answers[sub.id]) {
                  if (Number(answers[sub.id].answer)) {
                    if (answers[sub.id].answer <= 3) {
                      isParentGood = false;
                    }
                  } else {
                    if (answers[sub.id].answer != "yes") {
                      isParentGood = false;
                    }
                  }
                } else {
                  isParentGood = false;
                }
              });

              if (isParentGood == true) {
                stats[`good_answers_level_${crit.level}_count`] += 1;
                stats.good_answers_count += 1;

                if (crit.critical) {
                  stats[`critical_good_answers_level_${crit.level}_count`] += 1;
                }
              }
            } else {
              assessmentAnswer.push({
                chapter: c,
                section: null,
                crit: crit,
                answer:
                  answers[crit.id] && answers[crit.id].answer
                    ? answers[crit.id].answer
                    : "",
                files:
                  answers[crit.id] && answers[crit.id].files.length
                    ? "Oui"
                    : "Non",
              });
            }
          });
        }

        // critere dans sections
        c.sections.forEach((s) => {
          s.criteria.forEach((crit) => {
            calculateStats(crit, crit.subcriteria.length);
            if (crit.subcriteria.length) {
              let isParentGood = true;
              if (answers[crit.subcriteria[0].id]) {
                stats[`answers_level_${crit.level}_count`] += 1;
              }
              crit.subcriteria.forEach((sub) => {
                assessmentAnswer.push({
                  chapter: c,
                  section: s,
                  crit: crit,
                  subcrit: sub,
                  answer:
                    answers[sub.id] && answers[sub.id].answer
                      ? answers[sub.id].answer
                      : "",
                  files:
                    answers[sub.id] && answers[sub.id].files.length
                      ? "Oui"
                      : "Non",
                });
                if (answers[sub.id]) {
                  if (Number(answers[sub.id].answer)) {
                    if (answers[sub.id].answer <= 3) {
                      isParentGood = false;
                    }
                  } else {
                    if (answers[sub.id].answer != "yes") {
                      isParentGood = false;
                    }
                  }
                } else {
                  isParentGood = false;
                }
              });

              if (isParentGood) {
                stats[`good_answers_level_${crit.level}_count`] += 1;
                stats.good_answers_count += 1;

                if (crit.critical) {
                  stats[`critical_good_answers_level_${crit.level}_count`] += 1;
                }
              }
            } else {
              assessmentAnswer.push({
                chapter: c,
                section: s,
                crit: crit,
                answer:
                  answers[crit.id] && answers[crit.id].answer
                    ? answers[crit.id].answer
                    : "",
                files:
                  answers[crit.id] && answers[crit.id].files.length
                    ? "Oui"
                    : "Non",
              });
            }
          });
        });
      });

      this.janswers = assessmentAnswer;

      console.log("janswers:", this.janswers);

      this.stats = stats;

      (this.bodyData.series = [
        this.stats.good_answers_count,
        this.stats.answers_count -
          this.stats.good_answers_count -
          this.stats.unknown_answers_count,
        this.stats.criteria_count - this.stats.answers_count,
      ]),
        (this.bodyData1.series[0].data = [
          this.stats.good_answers_level_1_count,
          this.stats.answers_level_1_count -
            this.stats.good_answers_level_1_count -
            this.stats.unknown_answers_level_1_count,
          this.stats.unknown_answers_level_1_count,
          this.stats.criteria_level_1_count - this.stats.answers_level_1_count,
        ]);
      this.bodyData2.series[0].data = [
        this.stats.good_answers_level_2_count,
        this.stats.answers_level_2_count -
          this.stats.good_answers_level_2_count -
          this.stats.unknown_answers_level_2_count,
        this.stats.unknown_answers_level_2_count,
        this.stats.criteria_level_2_count - this.stats.answers_level_2_count,
      ];
      this.bodyData3.series[0].data = [
        this.stats.good_answers_level_3_count,
        this.stats.answers_level_3_count -
          this.stats.good_answers_level_3_count -
          this.stats.unknown_answers_level_3_count,
        this.stats.unknown_answers_evel_3_count,
        this.stats.criteria_level_3_count - this.stats.answers_level_3_count,
      ];

      this.bodyData4.series[0].data = [
        this.stats.good_answers_level_4_count,
        this.stats.answers_level_4_count -
          this.stats.good_answers_level_4_count -
          this.stats.unknown_answers_level_4_count,
        this.stats.unknown_answers_level_4_count,
        this.stats.criteria_level_4_count - this.stats.answers_level_4_count,
      ];
      this.done3 = true;
      console.log(stats, "hereeeeee");
    }
  },
  methods: {
    showFiles(critId) {
      if (this.answers[critId]) {
        return !this.answers[critId].files.length;
      } else {
        return true;
      }
    },
    async print() {
      // Pass the element id here
      await this.$htmlToPaper("printMe");
    },

    fetchAnswers() {
      new ApiService()
        .get(`/api/assessment/${this.$route.params.id}`)
        .then((res) => {
          let obj = {};
          res.data.data.Answers.forEach((e) => {
            obj[e.criterion_id] = { answer: e.answer, files: e.files };
          });

          this.ass_user = res.data.data.user;
          this.survey = res.data.data.survey;
          this.answers = obj;
          console.log(res.data);

          this.done2 = true;
        })
        .catch(() => {
          this.$router.push("/" + this.$i18n.locale + "/survey");
          this.$store.dispatch("Alert/activate", {
            text: "Une erreur est survenue, veuillez réessayer ultérieurement ou contacter l'administration.",
            type: "danger",
          });
        });
    },
    fetchResult() {
      new ApiService()
        .get("/api/assessment/" + this.$route.params.id + "/result")
        .then((res) => {
          this.chapters = res.data.chapters;
          this.done = true;

          console.log("chapters: ", this.chapters);
        })
        .catch((err) => {
          console.log("error: " + err);
        });
    },
    async printResults() {
      //Pass the element id here
      await this.$htmlToPaper("printMe");
    },
    generatePdf() {
      this.pdfLoader = true;
      this.$refs.html2Pdf.generatePdf();
    },
    openModal(id) {
      this.$bvModal.show("modal-" + id);
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollFunction() {
      if (
        document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300
      ) {
        this.displayUpBtn = true;
      } else {
        this.displayUpBtn = false;
      }
    },
  },
};
</script>

<template>
  <div>
    <b-container v-if="done && done2 && done3" fluid>
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">
            {{ $t("assessmentResults") }}
            {{ survey.reference + " - " + survey.title }} : {{ ass_user.name }}
          </h4>
        </template>
        <template v-slot:headerAction>
          <b-button class="mr-3" variant="primary" @click="printResults"
            ><span> {{ $t("buttons.print") }}</span>
          </b-button>

          <export-excel
            class="btn btn-success"
            :fields="json_fields"
            :data="janswers"
            :name="survey.reference + '_' + survey.title + '_' + ass_user.name"
          >
            <i class="fas fa-file-excel" />
            Excel
          </export-excel>
        </template>

        <div id="printMe">
          <div
            class="iq-card-body"
            style="
              padding-left: 0 !important;
              padding-right: 0 !important;
              padding-top: 0 !important;
            "
          >
            <section class="p-5 pdf-item" slot="pdf-item">
              <div class="row justify-content-center">
                <div class="col-lg-6">
                  <AmChart
                    color1="#32CE00"
                    color2="#FF3333"
                    color3="#A9A9A9"
                    element="1"
                    type="radial-pie"
                    :option="[
                      {
                        country: 'Satisfaits',
                        value: stats.good_answers_count,
                      },
                      {
                        country: $t('unsatisfactory'),
                        value: stats.answers_count - stats.good_answers_count,
                      },
                      {
                        country: $t('unknown'),
                        value: stats.unknown_answers_count,
                      },
                      {
                        country: $t('noAnswer'),
                        value: stats.criteria_count - stats.answers_count,
                      },
                    ]"
                    :height="280"
                  />
                </div>
              </div>

              <hr />
              <!-- <div class="row text-center"> -->
              <div class="row justify-content-center text-center">
                <div class="col-lg-6">
                  <ApexChart element="bar-1" :chartOption="bodyData1" />
                  <strong>{{ $t("level") }} </strong
                  ><span>
                    <i class="fas fa-star star-gold star" style="color: gold" />
                    <i class="fas fa-star star" style="color: Lightgrey" />
                    <i class="fas fa-star star" style="color: Lightgrey" />
                    <i class="fas fa-crown star" style="color: Lightgrey" />
                  </span>
                  <p class="text-danger">
                    <strong>{{
                      stats.critical_answers_level_1_count -
                      stats.critical_good_answers_level_1_count
                    }}</strong>
                    {{ $t("unsatisfactoryCrit") }}
                  </p>
                </div>
              </div>
              <div class="row justify-content-center text-center">
                <div class="col-lg-6">
                  <ApexChart element="bar-2" :chartOption="bodyData2" />
                  <strong>{{ $t("level") }} </strong
                  ><span>
                    <i class="fas fa-star star-gold star" style="color: gold" />
                    <i class="fas fa-star star-gold star" style="color: gold" />
                    <i class="fas fa-star star" style="color: Lightgrey" />
                    <i class="fas fa-crown star" style="color: Lightgrey" />
                  </span>
                  <p class="text-danger">
                    <strong>{{
                      stats.critical_answers_level_2_count -
                      stats.critical_good_answers_level_2_count
                    }}</strong>
                    {{ $t("unsatisfactoryCrit") }}
                  </p>
                </div>
              </div>

              <div class="row justify-content-center text-center">
                <div class="col-lg-6">
                  <ApexChart element="bar-3" :chartOption="bodyData3" />
                  <strong>{{ $t("level") }} </strong
                  ><span>
                    <i class="fas fa-star star-gold star" style="color: gold" />
                    <i class="fas fa-star star-gold star" style="color: gold" />
                    <i class="fas fa-star star-gold star" style="color: gold" />
                    <i class="fas fa-crown star" style="color: Lightgrey" />
                  </span>
                  <p class="text-danger">
                    <strong>{{
                      stats.critical_answers_level_3_count -
                      stats.critical_good_answers_level_3_count
                    }}</strong>
                    {{ $t("unsatisfactoryCrit") }}
                  </p>
                </div>
              </div>

              <div class="row justify-content-center text-center">
                <div class="col-lg-6">
                  <ApexChart element="bar-4" :chartOption="bodyData4" />
                  <strong>{{ $t("level") }} </strong
                  ><span>
                    <i class="fas fa-star star-gold star" style="color: gold" />
                    <i class="fas fa-star star-gold star" style="color: gold" />
                    <i class="fas fa-star star-gold star" style="color: gold" />
                    <i
                      class="fas fa-crown star-gold star"
                      style="color: gold"
                    />
                  </span>
                  <p class="text-danger">
                    <strong>{{
                      stats.critical_answers_level_4_count -
                      stats.critical_good_answers_level_4_count
                    }}</strong>
                    {{ $t("unsatisfactoryCrit") }}
                  </p>
                </div>
              </div>
              <!-- </div> -->
              <hr />
              <b-alert
                v-if="
                  stats.critical_answers_count >
                  stats.critical_good_answers_count
                "
                show
                variant="danger"
                style="justify-content: center"
                ><h4 class="text-center text-danger">
                  <strong>
                    {{
                      $t("resultWarning", [
                        stats.critical_answers_count -
                          stats.critical_good_answers_count,
                        stats.critical_criteria_count,
                        stats.critical_criteria_count -
                          stats.critical_answers_count,
                      ])
                    }}
                  </strong>
                </h4></b-alert
              >
            </section>

            <section v-for="chapter of chapters" :key="chapter.id">
              <div class="p-4">
                <h4 class="font-weight-bold" style="color: #ff7700">
                  {{ chapter.reference + " " + chapter.title }}
                </h4>
                <div
                  v-for="section of chapter.sections"
                  class="section"
                  :key="section.id"
                >
                  <h5 class="p-2 pt-4 text-primary">
                    {{ section.refernce + " " + section.title }}
                  </h5>
                  <div class="card">
                    <section
                      class="p-3 crit pdf-item"
                      v-for="(crit, i) of section.criteria"
                      :key="crit.id"
                    >
                      <hr v-if="i >= 1" />
                      <div class="d-flex" v-if="!crit.subcriteria.length">
                        <div class="col-lg-9" style="font-size: medium">
                          <p class="text-black">
                            {{ crit.refernce }} - {{ crit.question }}
                          </p>
                        </div>
                        <div class="col-lg-3 text-center criteria-options">
                          <div v-if="crit.critical" class="text-danger">
                            <i class="fas fa-exclamation-circle" />
                            {{ $t("critical") }}
                          </div>
                          <div class="text-center mt-3">
                            <span v-if="crit.level == 4">
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-crown star-gold star"
                                style="color: gold"
                              />
                            </span>
                            <span v-if="crit.level == 3">
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-crown star"
                                style="color: Lightgrey"
                              />
                            </span>
                            <span v-if="crit.level == 2">
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star"
                                style="color: Lightgrey"
                              />
                              <i
                                class="fas fa-crown star"
                                style="color: Lightgrey"
                              />
                            </span>
                            <span v-if="crit.level == 1">
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star"
                                style="color: Lightgrey"
                              />
                              <i
                                class="fas fa-star star"
                                style="color: Lightgrey"
                              />
                              <i
                                class="fas fa-crown star"
                                style="color: Lightgrey"
                              />
                            </span>
                          </div>
                          <div
                            style="font-size: medium"
                            class="text-center mt-3"
                          >
                            <span v-if="answers[crit.id]"
                              ><span class="mr-2">{{ $t("answer") }}:</span>
                              <span
                                class="font-weight-bold text-success"
                                v-if="answers[crit.id].answer === 'yes'"
                              >
                                <i class="far fa-check-circle"></i>
                                {{ $t("buttons.yes") }}</span
                              >
                              <span
                                class="font-weight-bold text-danger"
                                v-else-if="answers[crit.id].answer === 'no'"
                              >
                                <i class="far fa-times-circle"></i>
                                {{ $t("buttons.no") }}</span
                              >
                              <span
                                class="font-weight-bold"
                                v-else-if="
                                  answers[crit.id].answer === 'unknown'
                                "
                              >
                                {{ $t("buttons.unknown") }}</span
                              >
                              <span
                                class="font-weight-bold text-danger"
                                v-else-if="
                                  answers[crit.id].answer == '3' ||
                                  answers[crit.id].answer == '2' ||
                                  answers[crit.id].answer == '1'
                                "
                              >
                                {{ answers[crit.id].answer }}</span
                              >
                              <span
                                class="font-weight-bold text-success"
                                v-else-if="answers[crit.id].answer == '4'"
                                >4</span
                              >
                            </span>
                            <span class="font-weight-bold text-danger" v-else>{{
                              $t("noAnswer")
                            }}</span>
                          </div>
                          <div v-if="crit.require_files">
                            <b-button
                              class="mt-4"
                              variant="primary"
                              :disabled="showFiles(crit.id)"
                              @click="openModal(crit.id)"
                              ><i class="fas fa-paperclip"></i>
                              {{
                                !showFiles(crit.id)
                                  ? "Voir les pieces jointes"
                                  : $t("buttons.noFiles")
                              }}</b-button
                            >

                            <b-modal
                              :id="'modal-' + crit.id"
                              title="Pieces jointes"
                              :hide-footer="true"
                            >
                              <div
                                v-if="
                                  answers[crit.id] &&
                                  answers[crit.id].files.length
                                "
                              >
                                <li
                                  v-for="file of answers[crit.id].files"
                                  :key="file.id"
                                >
                                  <a
                                    :href="apiurl + '/storage/' + file.path"
                                    target="_blank"
                                    >{{ file.title }}</a
                                  >
                                </li>
                              </div>
                              <div v-else>
                                <span>Aucune piece jointe fournit</span>
                              </div>
                            </b-modal>
                          </div>
                        </div>
                      </div>
                      <div v-else style="margin-left: 20px">
                        <div style="font-size: medium">
                          <div class="d-flex">
                            <p class="col-9 text-black">
                              {{ crit.refernce }} - {{ crit.question }}
                            </p>
                            <div class="col-3 text-center mt-3">
                              <div v-if="crit.critical" class="text-danger">
                                <i class="fas fa-exclamation-circle" />
                                {{ $t("critical") }}
                              </div>
                              <span v-if="crit.level == 4">
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />

                                <i
                                  class="fas fa-crown star-gold star"
                                  style="color: gold"
                                />
                              </span>
                              <span v-if="crit.level == 3">
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />

                                <i
                                  class="fas fa-crown star"
                                  style="color: Lightgrey"
                                />
                              </span>
                              <span v-if="crit.level == 2">
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star"
                                  style="color: Lightgrey"
                                />
                                <i
                                  class="fas fa-crown star"
                                  style="color: Lightgrey"
                                />
                              </span>
                              <span v-if="crit.level == 1">
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star"
                                  style="color: Lightgrey"
                                />
                                <i
                                  class="fas fa-star star"
                                  style="color: Lightgrey"
                                />
                                <i
                                  class="fas fa-crown star"
                                  style="color: Lightgrey"
                                />
                              </span>
                            </div>
                          </div>
                          <div
                            v-for="subcrit of crit.subcriteria"
                            :key="subcrit.id"
                            class="d-flex sub-crit"
                          >
                            <div class="col-lg-9">
                              <p class="text-black">
                                {{ subcrit.refernce }} -
                                {{ subcrit.question }}
                              </p>
                            </div>
                            <div class="col-lg-3 text-center criteria-options">
                              <div
                                style="font-size: medium"
                                class="text-center mt-3"
                              >
                                <span v-if="answers[subcrit.id]"
                                  ><span class="mr-2">{{ $t("answer") }}:</span>
                                  <span
                                    class="font-weight-bold text-success"
                                    v-if="answers[subcrit.id].answer === 'yes'"
                                  >
                                    <i class="far fa-check-circle"></i>
                                    {{ $t("buttons.yes") }}</span
                                  >
                                  <span
                                    class="font-weight-bold text-danger"
                                    v-else-if="
                                      answers[subcrit.id].answer === 'no'
                                    "
                                  >
                                    <i class="far fa-times-circle"></i>
                                    {{ $t("buttons.no") }}</span
                                  >
                                  <span
                                    class="font-weight-bold"
                                    v-else-if="
                                      answers[subcrit.id].answer === 'unknown'
                                    "
                                  >
                                    {{ $t("buttons.unknown") }}</span
                                  >
                                  <span
                                    class="font-weight-bold text-danger"
                                    v-else-if="
                                      answers[subcrit.id].answer == '3' ||
                                      answers[subcrit.id].answer == '2' ||
                                      answers[subcrit.id].answer == '1'
                                    "
                                  >
                                    {{ answers[subcrit.id].answer }}</span
                                  >
                                  <span
                                    class="font-weight-bold text-success"
                                    v-else-if="answers[subcrit.id].answer == 4"
                                  >
                                    4</span
                                  >
                                </span>
                                <span
                                  class="font-weight-bold text-danger"
                                  v-else
                                  >{{ $t("noAnswer") }}</span
                                >
                              </div>
                              <div v-if="subcrit.require_files">
                                <b-button
                                  class="mt-4"
                                  variant="primary"
                                  :disabled="showFiles(subcrit.id)"
                                  @click="openModal(subcrit.id)"
                                  ><i class="fas fa-paperclip"></i>
                                  {{
                                    !showFiles(subcrit.id)
                                      ? " Voir les pieces jointes"
                                      : $t("buttons.noFiles")
                                  }}</b-button
                                >

                                <b-modal
                                  :id="'modal-' + subcrit.id"
                                  title="Pieces jointes"
                                  :hide-footer="true"
                                >
                                  <div
                                    v-if="
                                      answers[subcrit.id] &&
                                      answers[subcrit.id].files.length
                                    "
                                  >
                                    <li
                                      v-for="file of answers[subcrit.id].files"
                                      :key="file.id"
                                    >
                                      <a
                                        :href="apiurl + '/storage/' + file.path"
                                        target="_blank"
                                        >{{ file.title }}</a
                                      >
                                    </li>
                                  </div>
                                  <div v-else>
                                    <span>Aucune piece jointe fournit</span>
                                  </div>
                                </b-modal>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
                <section
                  class="p-3 crit-outside pdf-item"
                  v-for="(crit, i) of chapter.criteria"
                  :key="crit.id"
                >
                  <hr v-if="i >= 1" />
                  <div class="d-flex" v-if="!crit.subcriteria.length">
                    <div class="col-lg-9" style="font-size: medium">
                      <p class="text-black">
                        {{ crit.refernce }} - {{ crit.question }}
                      </p>
                    </div>
                    <div class="col-lg-3 text-center criteria-options">
                      <div v-if="crit.critical" class="text-danger">
                        <i class="fas fa-exclamation-circle" />
                        {{ $t("critical") }}
                      </div>
                      <div class="text-center mt-3">
                        <span v-if="crit.level == 4">
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />

                          <i
                            class="fas fa-crown star-gold star"
                            style="color: gold"
                          />
                        </span>
                        <span v-if="crit.level == 3">
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />

                          <i class="fas fa-crown star" />
                        </span>
                        <span v-if="crit.level == 2">
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star"
                            style="color: Lightgrey"
                          />
                          <i
                            class="fas fa-crown star"
                            style="color: Lightgrey"
                          />
                        </span>
                        <span v-if="crit.level == 1">
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star"
                            style="color: Lightgrey"
                          />
                          <i
                            class="fas fa-star star"
                            style="color: Lightgrey"
                          />
                          <i
                            class="fas fa-crown star"
                            style="color: Lightgrey"
                          />
                        </span>
                      </div>
                      <div style="font-size: medium" class="text-center mt-3">
                        <span v-if="answers[crit.id]"
                          ><span class="mr-2">{{ $t("answer") }}:</span>
                          <span
                            class="font-weight-bold text-success"
                            v-if="answers[crit.id].answer === 'yes'"
                          >
                            <i class="far fa-check-circle"></i>
                            {{ $t("buttons.yes") }}</span
                          >
                          <span
                            class="font-weight-bold text-danger"
                            v-else-if="answers[crit.id].answer === 'no'"
                          >
                            <i class="far fa-times-circle"></i>
                            {{ $t("buttons.no") }}</span
                          >
                          <span
                            class="font-weight-bold"
                            v-else-if="answers[crit.id].answer === 'unknown'"
                          >
                            {{ $t("buttons.unknown") }}</span
                          >
                          <span
                            class="font-weight-bold text-danger"
                            v-else-if="
                              answers[crit.id].answer == '3' ||
                              answers[crit.id].answer == '2' ||
                              answers[crit.id].answer == '1'
                            "
                          >
                            {{ answers[crit.id].answer }}</span
                          >
                          <span
                            class="font-weight-bold text-success"
                            v-else-if="answers[crit.id].answer == '4'"
                            >4</span
                          >
                        </span>
                        <span class="font-weight-bold text-danger" v-else>{{
                          $t("noAnswer")
                        }}</span>
                      </div>
                      <div v-if="crit.require_files">
                        <b-button
                          class="mt-4"
                          variant="primary"
                          :disabled="showFiles(crit.id)"
                          @click="openModal(crit.id)"
                          ><i class="fas fa-paperclip"></i>
                          {{
                            !showFiles(crit.id)
                              ? "Voir les pieces jointes"
                              : $t("buttons.noFiles")
                          }}</b-button
                        >

                        <b-modal
                          :id="'modal-' + crit.id"
                          title="Pieces jointes"
                          :hide-footer="true"
                        >
                          <div
                            v-if="
                              answers[crit.id] && answers[crit.id].files.length
                            "
                          >
                            <li
                              v-for="file of answers[crit.id].files"
                              :key="file.id"
                            >
                              <a
                                :href="apiurl + '/storage/' + file.path"
                                target="_blank"
                                >{{ file.title }}</a
                              >
                            </li>
                          </div>
                          <div v-else>
                            <span>Aucune piece jointe fournit</span>
                          </div>
                        </b-modal>
                      </div>
                    </div>
                  </div>
                  <div v-else style="margin-left: 20px">
                    <div style="font-size: medium">
                      <div class="d-flex">
                        <p class="text-black col-9">
                          {{ crit.refernce }} - {{ crit.question }}
                        </p>
                        <div v-if="crit.critical" class="text-danger col-3">
                          <i class="fas fa-exclamation-circle" />
                          {{ $t("critical") }}
                        </div>
                        <span v-if="crit.level == 4">
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />

                          <i
                            class="fas fa-crown star-gold star"
                            style="color: gold"
                          />
                        </span>
                        <span v-if="crit.level == 3">
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-crown star"
                            style="color: Lightgrey"
                          />
                        </span>
                        <span v-if="crit.level == 2">
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star"
                            style="color: Lightgrey"
                          />
                          <i
                            class="fas fa-crown star"
                            style="color: Lightgrey"
                          />
                        </span>
                        <span v-if="crit.level == 1">
                          <i
                            class="fas fa-star star-gold star"
                            style="color: gold"
                          />
                          <i
                            class="fas fa-star star"
                            style="color: Lightgrey"
                          />
                          <i
                            class="fas fa-star star"
                            style="color: Lightgrey"
                          />
                          <i
                            class="fas fa-crown star"
                            style="color: Lightgrey"
                          />
                        </span>
                      </div>

                      <div
                        v-for="subcrit of crit.subcriteria"
                        :key="subcrit.id"
                        class="d-flex sub-crit"
                      >
                        <div class="col-lg-9">
                          <p class="text-black">
                            {{ subcrit.refernce }} - {{ subcrit.question }}
                          </p>
                        </div>
                        <div class="col-lg-3 text-center criteria-options">
                          <div class="text-center mt-3">
                            <div
                              style="font-size: medium"
                              class="text-center mt-3"
                            >
                              <span v-if="answers[subcrit.id]"
                                ><span class="mr-2">{{ $t("answer") }}:</span>
                                <span
                                  class="font-weight-bold text-success"
                                  v-if="answers[subcrit.id].answer === 'yes'"
                                >
                                  <i class="far fa-check-circle"></i>
                                  {{ $t("buttons.yes") }}</span
                                >
                                <span
                                  class="font-weight-bold text-danger"
                                  v-else-if="
                                    answers[subcrit.id].answer === 'no'
                                  "
                                >
                                  <i class="far fa-times-circle"></i>
                                  {{ $t("buttons.no") }}</span
                                >
                                <span
                                  class="font-weight-bold"
                                  v-else-if="
                                    answers[subcrit.id].answer === 'unknown'
                                  "
                                >
                                  {{ $t("buttons.unknown") }}</span
                                >
                                <span
                                  class="font-weight-bold text-danger"
                                  v-else-if="
                                    answers[subcrit.id].answer == '3' ||
                                    answers[subcrit.id].answer == '2' ||
                                    answers[subcrit.id].answer == '1'
                                  "
                                >
                                  {{ answers[subcrit.id].answer }}</span
                                >
                                <span
                                  class="font-weight-bold text-success"
                                  v-else-if="answers[subcrit.id].answer == '4'"
                                  >4</span
                                >
                              </span>
                              <span
                                class="font-weight-bold text-danger"
                                v-else
                                >{{ $t("noAnswer") }}</span
                              >
                            </div>
                            <div v-if="subcrit.require_files">
                              <b-button
                                class="mt-4"
                                variant="primary"
                                @click="openModal(subcrit.id)"
                                :disabled="showFiles(subcrit.id)"
                                ><i class="fas fa-paperclip"></i>
                                {{
                                  !showFiles(subcrit.id)
                                    ? " Voir les pieces jointes"
                                    : $t("buttons.noFiles")
                                }}</b-button
                              >

                              <b-modal
                                :id="'modal-' + subcrit.id"
                                title="Pieces jointes"
                                :hide-footer="true"
                              >
                                <div
                                  v-if="
                                    answers[subcrit.id] &&
                                    answers[subcrit.id].files.length
                                  "
                                >
                                  <li
                                    v-for="file of answers[subcrit.id].files"
                                    :key="file.id"
                                  >
                                    <a
                                      :href="apiurl + '/storage/' + file.path"
                                      target="_blank"
                                      >{{ file.title }}</a
                                    >
                                  </li>
                                </div>
                                <div v-else>
                                  <span>Aucune piece jointe fournit</span>
                                </div>
                              </b-modal>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <!-- <div class="html2pdf__page-break" /> -->
            </section>
          </div>
        </div>
      </iq-card>
      <b-button v-if="displayUpBtn" id="btn-back-to-top" @click="scrollTop">
        <i style="margin-right: 0" class="fas fa-arrow-up"></i>
      </b-button>
    </b-container>
    <Loader v-if="!done" />
  </div>
</template>

<style>
.star {
  margin-left: 7px;
}
.star-gold {
  color: gold;
}
</style>
