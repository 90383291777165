<template>
  <b-container id="alert" v-if="alert.active" fluid>
    <iq-card :class="'alert alert-' + alert.type" role="alert">
      <div class="iq-alert-icon">
        <i class="ri-information-line"></i>
      </div>
      <div class="iq-alert-text">{{ alert.text }}</div>
    </iq-card>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import iqCard from "../cards/iq-card.vue";
export default {
  components: { iqCard },
  computed: {
    ...mapGetters({
      alert: "Alert/getAlert"
    })
  }
};
</script>

<style scoped>
#alert {
  animation-name: open;
  animation-duration: 5000ms;
}

@keyframes open {
  0% {
    height: 0;
    opacity: 0;
  }
  10% {
    height: 85px;
    opacity: 100;
  }
  90% {
    height: 85px;
    opacity: 100;
  }
  100% {
    height: 0px;
    opacity: 0;
  }
}
</style>
