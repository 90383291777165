<script>
import Loader from "../../components/xray/Loader";
import ApiService from "../../Utils/axios";
import { mapGetters } from "vuex";
export default {
  name: "grid-list",
  components: { Loader },
  computed: {
    ...mapGetters({ user: "Auth/getCurrentUser" }),
  },
  mounted() {
    document.title = `${this.$t("pageTitles.evaluationGrids")} - Auditor`;
  },
  beforeMount() {
    this.fetchList();
  },
  data() {
    return {
      list: [],
      modalMsg: "",
      done: true,
      item: null,
      columns: [
        { editable: true, deleteable: true },
        { label: "Référence", key: "reference", class: "text-center" },
        { label: "Intitulé", key: "title", class: "text-left" },
        // { label: "Resumé", key: "summary", class: "text-left" },
        {
          label: "Action",
          key: "action",
          class: "text-center",
        },
      ],
    };
  },
  methods: {
    remove(item) {
      let api = new ApiService();
      api
        .delete("/api/grid/" + item.id)
        .then((res) => {
          this.fetchList();
          this.$store.dispatch("Alert/activate", {
            text: "Grille supprimé avec succée",
            type: "success",
          });
        })
        .catch(() => {
          this.$store.dispatch("Alert/activate", {
            text: "Le guide ne peut pas être supprimé parce qu'il est utilisé par d'autres entités du système",
            type: "danger",
          });
        });
    },
    onRemove(item) {
      this.item = item;
      this.modalMsg = item.title;
      this.$bvModal.show("modal-1");
    },
    fetchList() {
      this.done = false;
      new ApiService()
        .get("/api/grid")
        .then((res) => {
          const data = Array.isArray(res.data) ? res.data : res.data.data;
          this.list = data.sort((a, b) => a.reference - b.reference);
          this.done = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<template>
  <div>
    <b-modal id="modal-1" title="Attention!" @ok="remove(item)">
      <p class="my-4">
        Etes vous sure de vouloir supprimer l'établissement
        <span class="bold text-danger">{{ modalMsg }}</span> ?
      </p>
    </b-modal>
    <b-container v-if="done && list.length" fluid>
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title text-primary">
            {{ $t("pageTitles.evaluationGrids") }}
          </h4>
        </template>
        <template v-slot:headerAction>
          <b-button
            class="m-2"
            variant="primary"
            style="white-space: nowrap"
            :to="'/' + $i18n.locale + '/grid/create'"
            v-if="user.roles.includes('ADMIN')"
            ><i class="fas fa-plus"></i> Ajouter</b-button
          ></template
        >
        <div class="iq-card-body">
          <iq-card v-for="item of list" :key="item.id" class="bg-primary mb-3">
            <div class="p-2 px-4 rounded d-flex justify-content-between">
              <h4 class="text-white mt-1">{{ item.title }}</h4>
              <div class="">
                <b-button variant=" iq-bg-primary mr-1 mt-1" size="sm"
                  ><i class="fas fa-book-open m-0"></i
                ></b-button>
                <b-button variant=" iq-bg-success mr-1 mt-1" size="sm"
                  ><i class="ri-ball-pen-fill m-0"></i
                ></b-button>
                <b-button
                  variant=" iq-bg-danger mr-1 mt-1"
                  size="sm"
                  @click="onRemove(item)"
                  ><i class="ri-delete-bin-line m-0"></i
                ></b-button>
              </div>
            </div>
          </iq-card>
          <!-- <iq-card class="bg-primary mb-3">
            <div class="p-2 px-4 rounded d-flex justify-content-between">
              <h4 class="text-white mt-1">Titre</h4>
              <div class="">
                <b-button variant=" iq-bg-primary mr-1 mt-1" size="sm"
                  ><i class="fas fa-book-open m-0"></i
                ></b-button>
                <b-button variant=" iq-bg-success mr-1 mt-1" size="sm"
                  ><i class="ri-ball-pen-fill m-0"></i
                ></b-button>
                <b-button
                  variant=" iq-bg-danger mr-1 mt-1"
                  size="sm"
                  @click="onRemove(item)"
                  ><i class="ri-delete-bin-line m-0"></i
                ></b-button>
              </div>
            </div>
          </iq-card> -->
        </div>
      </iq-card>
    </b-container>
    <b-container v-else style="height: 75vh" class="text-center" fluid
      ><h5>Aucune grille d'evaluation trouvé dans notre base de données.</h5>
      <b-button
        class="mt-3"
        variant="primary"
        :to="'/' + $i18n.locale + '/grid/create'"
        ><i class="fas fa-plus"></i> Ajouter
      </b-button>
    </b-container>

    <Loader v-if="!done" />
  </div>
</template>
