<template>
  <!-- TOP Nav Bar -->
  <div
    class="iq-top-navbar"
    :style="!user.roles.includes('MANAGER') && !user.roles.includes('STANDARD') ? 'width: calc(100% - 60px);' : ''"
  >
    <div
      class="iq-navbar-custom"
      :class="
        horizontal ? 'd-flex align-items-center justify-content-between' : ''
      "
    >
      <div class="iq-sidebar-logo">
        <div class="top-logo">
          <router-link :to="homeURL">
            <img :src="euromedi_logo" style="width:14rem" alt="logo" />
          </router-link>
        </div>
      </div>
      <div class="iq-menu-horizontal" v-if="horizontal">
        <div class="iq-sidebar-menu">
          <List :items="items" class="d-flex"></List>
        </div>
      </div>
      <nav class="navbar navbar-expand-lg navbar-light p-0">
        <div class="iq-search-bar mx-4 d-flex flex-wrap p-2">
          <div class="">
            <img
              :src="euromedi_logo"
              style="width:14rem"
              alt="logo"
              class="mr-5"
            />
          </div>
          <div class="d-none d-xl-block">
            <img
              class="customer_logo"
              style="max-height: 4rem; width: auto; margin-left: 30px"
              v-if="user.institution.logo"
              :src="apiUrl + '/storage/' + user.institution.logo.path"
              alt="logo"
            />
          </div>
        </div>
        <b-navbar-toggle target="nav-collapse">
          <i class="ri-menu-3-line"></i>
        </b-navbar-toggle>
        <div class="iq-menu-bt align-self-center">
          <div class="wrapper-menu" @click="miniSidebar">
            <div class="main-circle"><i class="ri-more-fill"></i></div>
            <div class="hover-circle"><i class="ri-more-2-fill"></i></div>
          </div>
        </div>
        <b-collapse id="nav-collapse" is-nav>
          <slot name="responsiveRight" />
        </b-collapse>
        <slot name="right" />
      </nav>
    </div>
  </div>
  <!-- TOP Nav Bar END -->
</template>
<script>
import List from "../menus/ListStyle1";
import { mapGetters } from "vuex";
import SideBarItems from "../../../FackApi/json/SideBar";
export default {
  name: "NavBarStyle1",
  props: {
    homeURL: { type: Object, default: () => ({ name: "layout1.dashboard" }) },
    title: { type: String, default: "Dashboard" },
    logo: { type: String, default: require("../../../assets/images/logo.png") },
    horizontal: { type: Boolean, default: false },
    items: { type: Array }
  },
  mounted() {
    document.addEventListener("click", this.closeSearch, true);
  },
  components: {
    List
  },
  computed: {
    ...mapGetters({ user: "Auth/getCurrentUser" })
  },
  data() {
    return {
      apiUrl: process.env.VUE_APP_API,
      sidebar: SideBarItems,
      globalSearch: "",
      showSearch: false,
      showMenu: false,
      euromedi_logo: require("../../../assets/images/euromedi_logo.png")
    };
  },
  methods: {
    miniSidebar() {
      this.$emit("toggle");
    }
  }
};
</script>
