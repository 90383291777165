<script>
import ApiService from "../../Utils/axios";
import Table from "./EditableTable";
import TableManager from "./EditableTableManager";
import TableStandard from "./EditableTableStandard";
import Loader from "../../components/xray/Loader";
import { mapGetters } from "vuex";

export default {
  components: { Table, TableManager, TableStandard, Loader },
  computed: {
    ...mapGetters({ user: "Auth/getCurrentUser" }),
  },
  data() {
    return {
      list: [],
      done: false,
      columns: [
        {
          label: this.$t("tables.survey"),
          key: "survey.title",
          class: "text-left",
        },
        { label: "Guides", key: "guides", class: "text-left" },
        {
          label: this.$t("tables.institution"),
          key: "survey.institution.name",
          class: "text-left",
        },
        {
          label: this.$t("tables.functions"),
          key: "services",
          class: "text-left",
        },
        { label: this.$t("tables.user"), key: "user.name", class: "text-left" },
        { label: this.$t("tables.progress"), key: "progress" },
        {
          label: this.$t("tables.display"),
          key: "action",
          class: "text-center",
        },
      ],
      columnsMgr: [
        {
          label: this.$t("tables.functions"),
          key: "survey.title",
          class: "text-left",
        },
        {
          label: this.$t("tables.user"),
          key: "services",
          class: "text-left",
        },
        { label: this.$t("tables.user"), key: "user.name", class: "text-left" },
        { label: this.$t("tables.progress"), key: "progress" },
        {
          label: this.$t("tables.display"),
          key: "action",
          class: "text-center",
        },
      ],
      columnsStd: [
        {
          label: this.$t("tables.user"),
          key: "survey.title",
          class: "text-left",
        },
        { label: this.$t("tables.user"), key: "user.name", class: "text-left" },
        { label: this.$t("tables.progress"), key: "progress" },
        {
          label: this.$t("tables.display"),
          key: "action",
          class: "text-center",
        },
      ],
    };
  },
  beforeMount() {
    this.fetchList();
  },
  mounted() {
    document.title = `${this.$t("pageTitles.results")} - Auditor`;
  },
  methods: {
    fetchList() {
      this.done = false;
      new ApiService()
        .get("/api/assessment")
        .then((res) => {
          this.list = res.data.data;
          this.done = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<template>
  <div>
    <Table
      v-if="
        done &&
        list.length &&
        (user.roles.includes('ADMIN') || user.roles.includes('SUPER_ADMIN'))
      "
      :tableTitle="$t('pageTitles.results')"
      :rows="list"
      :cols="columns"
      :fetchList="fetchList"
    />
    <TableManager
      v-if="
        done &&
        list.length &&
        !user.roles.includes('ADMIN') &&
        !user.roles.includes('SUPER_ADMIN') &&
        user.roles.includes('MANAGER')
      "
      :tableTitle="$t('pageTitles.results')"
      :rows="list"
      :cols="columnsMgr"
      :fetchList="fetchList"
    />
    <TableStandard
      v-if="
        done &&
        list.length &&
        !user.roles.includes('ADMIN') &&
        !user.roles.includes('SUPER_ADMIN') &&
        (user.roles.includes('STANDARD') || user.roles.includes('GUEST'))
      "
      :tableTitle="$t('pageTitles.results')"
      :rows="list"
      :cols="columnsStd"
      :fetchList="fetchList"
    />
    <b-container
      v-if="done && !list.length"
      style="height: 75vh"
      class="text-center"
      fluid
      ><h5>Aucune evaluation trouvée dans notre base de données.</h5>
    </b-container>
    <Loader v-if="!done" />
  </div>
</template>

<style>
.w-5 {
  max-width: 5%;
}
</style>
