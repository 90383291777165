import { remove } from "js-cookie";

const state = {
  type: "",
  text: "",
  active: false
};

const mutations = {
  SET_ALERT(state, { text, type }) {
    state.text = text;
    state.type = type;
    state.active = true;
  },
  REMOVE_ALERT(state) {
    state.active = false;
  }
};

const getters = {
  // Whether the user is currently logged in.
  getAlert(state) {
    return { active: state.active, text: state.text, type: state.type };
  }
};

const actions = {
  activate({ commit, dispatch }, { type, text }) {
    commit("SET_ALERT", { type: type, text: text });
    setTimeout(() => {
      dispatch("remove");
    }, 5000);
  },
  remove({ commit }) {
    commit("REMOVE_ALERT");
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
