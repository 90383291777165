<script>
import Loader from "../../components/xray/Loader";
import ApiService from "../../Utils/axios";
import VueHtml2pdf from "vue-html2pdf";
import { htmlToPaper } from "vue-html-to-paper";

import axios from "axios";
export default {
  components: { Loader, VueHtml2pdf, htmlToPaper },
  data() {
    return {
      levelFilter: [1, 2, 3, 4],
      filter1: true,
      filter2: true,
      filter3: true,
      filter4: true,
      hide: false,
      done: false,
      guide: null,
      pdfLoader: false,
      displayUpBtn: false,
    };
  },
  beforeMount() {
    this.fetchGuide();
  },
  mounted() {
    window.onscroll = () => {
      this.scrollFunction();
    };
  },

  methods: {
    fetchGuide() {
      new ApiService()
        .get("/api/guide/" + this.$route.params.id)
        .then((res) => {
          this.guide = res.data.data;
          document.title = res.data.data.title + " - Auditor";
          this.done = true;
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    scrollTo(eleId) {
      const y =
        document.getElementById(eleId).getBoundingClientRect().top +
        window.scrollY -
        100;
      window.scroll({
        top: y,
        behavior: "smooth",
      });
    },

    async print() {
      // Pass the element id here
      await this.$htmlToPaper("printMe");
    },

    generatePdf() {
      this.pdfLoader = true; //++
      this.$ref.html2Pdf.generatePdf(); //++
    },
    scrollTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    scrollFunction() {
      if (
        document.body.scrollTop > 300 ||
        document.documentElement.scrollTop > 300
      ) {
        this.displayUpBtn = true;
      } else {
        this.displayUpBtn = false;
      }
    },
    filterCondition(critLevel) {
      let res = "";
      this.levelFilter.forEach((e, i) => {
        if (this.levelFilter.length == 1 || this.levelFilter.length - 1 == i) {
          res += `${e} == ${critLevel}`;
        } else {
          res += `${e} == ${critLevel} ||`;
        }
      });
      return eval(res);
    },
    handleFilter(input, level) {
      if (input) {
        let arr = this.levelFilter;
        arr.push(level);
        this.levelFilter = arr;
      } else {
        if (this.levelFilter == 1) {
          return;
        }
        this.levelFilter = this.levelFilter.filter((e) => e != level);
      }

      console.log(this.levelFilter);
    },
  },
};
</script>

<template>
  <div>
    <b-container v-if="done" fluid>
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">Visualiser un guide</h4>
        </template>

        <template v-slot:headerAction>
          <b-button variant="primary" @click="print"
            ><span> {{ $t("buttons.print") }}</span>
          </b-button></template
        >

        <div
          class="iq-card-body"
          style="
            padding-left: 0 !important;
            padding-right: 0 !important;
            padding-top: 0 !important;
          "
        >
          <div id="printMe">
            <!-- PDF Content Here -->
            <h3 class="text-primary p-4 text-center">
              {{ guide.title }}
            </h3>
            <div class="p-4">
              <h4 class="font-weight-bold" style="color: #ff7700">Sommaire</h4>
              <h5 class="ml-3 text-primary">Introduction générale</h5>
              <div v-for="chapter of guide.chapters" :key="chapter.id">
                <div
                  @click="scrollTo('cha' + chapter.id)"
                  class="summary-click d-flex justify-content-between mt-3"
                >
                  <h5 class="ml-3 text-primary">
                    {{ chapter.reference + " - " + chapter.title }}
                  </h5>
                  <h5 class="text-white">
                    Aller vers <i class="fas fa-arrow-down"></i>
                  </h5>
                </div>
                <div
                  v-for="section of chapter.sections"
                  class="ml-5"
                  :key="section.id"
                >
                  <div
                    @click="scrollTo('sec' + section.id)"
                    class="mt-2 summary-click d-flex justify-content-between"
                  >
                    <h6>
                      {{ section.refernce + " - " + section.title }}
                    </h6>
                    <h5 class="text-white">
                      Aller vers <i class="fas fa-arrow-down"></i>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <div class="p-4">
              <h4 class="font-weight-bold mb-2" style="color: #ff7700">
                Introduction générale
              </h4>
              <div
                class="text-black ml-5 mr-5 text-justify"
                v-html="guide.summary"
              ></div>
            </div>
            <hr />
            <div class="pull-right px-4">
              <b-dropdown text="Filtrer par niveau" variant="primary">
                <b-form-checkbox
                  @input="(e) => handleFilter(e, 1)"
                  v-model="filter1"
                >
                  {{ $t("level") }} 1
                </b-form-checkbox>
                <b-form-checkbox
                  @input="(e) => handleFilter(e, 2)"
                  v-model="filter2"
                >
                  {{ $t("level") }} 2
                </b-form-checkbox>
                <b-form-checkbox
                  @input="(e) => handleFilter(e, 3)"
                  v-model="filter3"
                >
                  {{ $t("level") }} 3
                </b-form-checkbox>
                <b-form-checkbox
                  @input="(e) => handleFilter(e, 4)"
                  v-model="filter4"
                >
                  {{ $t("level") }} 4
                </b-form-checkbox>
                <hr />
                <b-form-checkbox v-model="hide">Masquer </b-form-checkbox>
              </b-dropdown>
            </div>

            <!-- BOUCLE SUR L'ENSEMBLE DES CHAPITRES DU GUIDE -->
            <div v-for="chapter of guide.chapters" :key="chapter.id">
              <div class="p-4">
                <h4
                  :id="'cha' + chapter.id"
                  class="font-weight-bold"
                  style="color: #ff7700"
                >
                  {{ chapter.reference + " - " + chapter.title }}
                </h4>
                <!-- CHAPITRES AVEC DES SECTIONS -->
                <div
                  v-for="section of chapter.sections"
                  class="section"
                  :key="section.id"
                >
                  <h5 :id="'sec' + section.id" class="p-2 pt-4 text-primary">
                    {{ section.refernce + " " + section.title }}
                  </h5>
                  <div class="card">
                    <section
                      class="pdf-item"
                      v-for="(crit, i) of section.criteria"
                      :key="crit.id"
                    >
                      <!-- FILTRES PAR NIVEAUX -->
                      <div v-if="levelFilter.length">
                        <div
                          v-if="filterCondition(crit.level)"
                          class="crit p-3"
                        >
                          <hr v-if="i >= 1" />
                          <!-- PAS DE SOUS-CRITERES DANS LES CRITERES -->
                          <div class="d-flex" v-if="!crit.subcriteria.length">
                            <div
                              class="col-10 col-md-10"
                              style="font-size: medium"
                            >
                              <p class="text-black">
                                <i
                                  ><b>{{ crit.refernce }}</b></i
                                >
                                - <b>{{ crit.question }}</b>
                              </p>
                              <div
                                style="
                                  border: 2px solid #ff7700;
                                  border-radius: 10px;
                                "
                                class="p-3"
                                v-if="crit.requirements"
                              >
                                <span
                                  class="font-weight-bold"
                                  style="color: #ff7700"
                                  >{{ $t("buttons.requirements") }}</span
                                >
                                <nl2br
                                  tag="p"
                                  :text="crit.requirements"
                                  class-name="pt-2 pl-3"
                                />
                              </div>
                              <div
                                v-if="crit.explanations"
                                style="
                                  border: 2px solid #2f48c5;
                                  border-radius: 10px;
                                "
                                class="p-3 mt-3"
                              >
                                <span class="text-primary font-weight-bold">{{
                                  $t("buttons.explanations")
                                }}</span>
                                <nl2br
                                  tag="p"
                                  :text="crit.explanations"
                                  class-name="pt-2 pl-3"
                                />
                              </div>
                            </div>
                            <div
                              class="col-lg-2 col-md-2 text-center criteria-options"
                            >
                              <div v-if="crit.critical" class="text-danger">
                                <i class="fas fa-exclamation-circle" />
                                {{ $t("critical") }}
                              </div>
                              <div v-if="!hide" class="text-center mt-3">
                                <span v-if="crit.level == 4">
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />

                                  <i
                                    class="fas fa-crown star-gold star"
                                    style="color: gold"
                                  />
                                </span>
                                <span v-if="crit.level == 3">
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />

                                  <i
                                    class="fas fa-crown star"
                                    style="color: Lightgrey"
                                  />
                                </span>
                                <span v-if="crit.level == 2">
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star"
                                    style="color: Lightgrey"
                                  />

                                  <i
                                    class="fas fa-crown star"
                                    style="color: Lightgrey"
                                  />
                                </span>
                                <span v-if="crit.level == 1">
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star"
                                    style="color: Lightgrey"
                                  />
                                  <i
                                    class="fas fa-star star"
                                    style="color: Lightgrey"
                                  />

                                  <i
                                    class="fas fa-crown star"
                                    style="color: Lightgrey"
                                  />
                                </span>
                              </div>
                              <div
                                v-if="crit.require_files && !hide"
                                class="text-primary"
                              >
                                <i class="fas fa-file-text"></i> Doc. requis
                              </div>
                              <div v-if="crit.optional">Critère optionnel</div>

                              <div v-if="!hide">
                                Valeurs possibles :
                                <p v-if="crit.answer === 'yesno'">OUI/NON</p>
                                <p v-else-if="crit.answer === 'range'">
                                  COTE de 1 à 4
                                </p>
                                <p v-else>Erreur!</p>
                              </div>
                            </div>
                          </div>
                          <!-- SOUS-CRITERES DANS LES CRITERES -->
                          <div v-else-if="!hide" style="margin-left: 10px">
                            <div style="font-size: medium">
                              <div class="d-flex">
                                <div class="col-10">
                                  <p class="text-black">
                                    <i
                                      ><b>{{ crit.refernce }}</b></i
                                    >
                                    - <b>{{ crit.question }}</b>
                                  </p>
                                  <!-- AJOUT SAMI LE 26-05-22 -->
                                  <div
                                    style="
                                      border: 2px solid #ff7700;
                                      border-radius: 10px;
                                    "
                                    class="p-3"
                                    v-if="crit.requirements"
                                  >
                                    <span
                                      class="font-weight-bold"
                                      style="color: #ff7700"
                                      >{{ $t("buttons.requirements") }}</span
                                    >
                                    <nl2br
                                      tag="p"
                                      :text="crit.requirements"
                                      class-name="pt-2 pl-3"
                                    />
                                  </div>
                                  <div
                                    v-if="crit.explanations"
                                    style="
                                      border: 2px solid #2f48c5;
                                      border-radius: 10px;
                                    "
                                    class="p-3 mt-3"
                                  >
                                    <span
                                      class="text-primary font-weight-bold"
                                      >{{ $t("buttons.explanations") }}</span
                                    >
                                    <nl2br
                                      tag="p"
                                      :text="crit.explanations"
                                      class-name="pt-2 pl-3"
                                    />
                                  </div>
                                </div>

                                <!-- FIN AJOUT SAMI LE 26-05-22 -->
                                <div class="text-center col-2">
                                  <div v-if="crit.critical" class="text-danger">
                                    <i class="fas fa-exclamation-circle" />
                                    {{ $t("critical") }}
                                  </div>
                                  <div class="text-center">
                                    <span v-if="crit.level == 4">
                                      <i
                                        class="fas fa-star star-gold star"
                                        style="color: gold"
                                      />
                                      <i
                                        class="fas fa-star star-gold star"
                                        style="color: gold"
                                      />
                                      <i
                                        class="fas fa-star star-gold star"
                                        style="color: gold"
                                      />

                                      <i
                                        class="fas fa-crown star-gold star"
                                        style="color: gold"
                                      />
                                    </span>
                                    <span v-if="crit.level == 3">
                                      <i
                                        class="fas fa-star star-gold star"
                                        style="color: gold"
                                      />
                                      <i
                                        class="fas fa-star star-gold star"
                                        style="color: gold"
                                      />
                                      <i
                                        class="fas fa-star star-gold star"
                                        style="color: gold"
                                      />

                                      <i
                                        class="fas fa-crown star"
                                        style="color: Lightgrey"
                                      />
                                    </span>
                                    <span v-if="crit.level == 2">
                                      <i
                                        class="fas fa-star star-gold star"
                                        style="color: gold"
                                      />
                                      <i
                                        class="fas fa-star star-gold star"
                                        style="color: gold"
                                      />
                                      <i
                                        class="fas fa-star star"
                                        style="color: Lightgrey"
                                      />

                                      <i
                                        class="fas fa-crown star"
                                        style="color: Lightgrey"
                                      />
                                    </span>
                                    <span v-if="crit.level == 1">
                                      <i
                                        class="fas fa-star star-gold star"
                                        style="color: gold"
                                      />
                                      <i
                                        class="fas fa-star star"
                                        style="color: Lightgrey"
                                      />
                                      <i
                                        class="fas fa-star star"
                                        style="color: Lightgrey"
                                      />

                                      <i
                                        class="fas fa-crown star"
                                        style="color: Lightgrey"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <!-- BOUCLE SUR LES SOUS-CRITERES -->
                              <div
                                v-for="subcrit of crit.subcriteria"
                                :key="subcrit.id"
                                class="d-flex sub-crit"
                              >
                                <div class="col-lg-10 col-md-10">
                                  <p class="text-black">
                                    <i
                                      ><b>{{ subcrit.refernce }}</b></i
                                    >
                                    - <b>{{ subcrit.question }}</b>
                                  </p>
                                  <div
                                    style="
                                      border: 2px solid #ff7700;
                                      border-radius: 10px;
                                    "
                                    class="p-3"
                                    v-if="subcrit.requirements"
                                  >
                                    <span
                                      class="font-weight-bold"
                                      style="color: #ff7700"
                                      >{{ $t("buttons.requirements") }}</span
                                    >
                                    <nl2br
                                      tag="p"
                                      :text="subcrit.requirements"
                                      class-name="pt-2 pl-3"
                                    />
                                  </div>
                                  <div
                                    v-if="subcrit.explanations"
                                    style="
                                      border: 2px solid #2f48c5;
                                      border-radius: 10px;
                                    "
                                    class="p-3 mt-3"
                                  >
                                    <span
                                      class="text-primary font-weight-bold"
                                      >{{ $t("buttons.explanations") }}</span
                                    >
                                    <nl2br
                                      tag="p"
                                      :text="subcrit.explanations"
                                      class-name="pt-2 pl-3"
                                    />
                                  </div>
                                </div>
                                <div
                                  class="col-lg-2 col-md-2 text-center criteria-options"
                                >
                                  <div
                                    v-if="subcrit.critical"
                                    class="text-danger"
                                  >
                                    <i class="fas fa-exclamation-circle" />
                                    {{ $t("critical") }}
                                  </div>
                                  <div
                                    v-if="subcrit.require_files && !hide"
                                    class="text-primary"
                                  >
                                    <i class="fas fa-file-text"></i> Doc. requis
                                  </div>

                                  <div v-if="subcrit.optional && !hide">
                                    Critère optionnel
                                  </div>

                                  <div v-if="!hide">
                                    Valeurs possibles :
                                    <p v-if="subcrit.answer === 'yesno'">
                                      OUI/NON
                                    </p>
                                    <p v-else-if="subcrit.answer === 'range'">
                                      COTE de 1 à 4
                                    </p>
                                    <p v-else>Erreur!</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- PAS DE FILTRES PAR NIVEAUX -->
                      <div v-else>
                        <hr v-if="i >= 1" />
                        <!-- PAS DE SOUS-CRITERES DANS LES CRITERES -->
                        <div v-if="!crit.subcriteria.length" class="d-flex">
                          <div
                            class="col-10 col-md-10"
                            style="font-size: medium"
                          >
                            <p class="text-black">
                              <i
                                ><b>{{ crit.refernce }}</b></i
                              >
                              - <b>{{ crit.question }}</b>
                            </p>
                            <div
                              style="
                                border: 2px solid #ff7700;
                                border-radius: 10px;
                              "
                              class="p-3"
                              v-if="crit.requirements"
                            >
                              <span
                                class="font-weight-bold"
                                style="color: #ff7700"
                                >{{ $t("buttons.requirements") }}</span
                              >
                              <nl2br
                                tag="p"
                                :text="crit.requirements"
                                class-name="pt-2 pl-3"
                              />
                            </div>
                            <div
                              v-if="crit.explanations"
                              style="
                                border: 2px solid #2f48c5;
                                border-radius: 10px;
                              "
                              class="p-3 mt-3"
                            >
                              <span class="text-primary font-weight-bold">{{
                                $t("buttons.explanations")
                              }}</span>
                              <nl2br
                                tag="p"
                                :text="crit.explanations"
                                class-name="pt-2 pl-3"
                              />
                            </div>
                          </div>
                          <div
                            class="col-lg-2 col-md-2 text-center criteria-options"
                          >
                            <div v-if="crit.critical" class="text-danger">
                              <i class="fas fa-exclamation-circle" />
                              {{ $t("critical") }}
                            </div>
                            <div v-if="!hide" class="text-center mt-3">
                              <span v-if="crit.level == 4">
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />

                                <i
                                  class="fas fa-crown star-gold star"
                                  style="color: gold"
                                />
                              </span>
                              <span v-if="crit.level == 3">
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />

                                <i
                                  class="fas fa-crown star"
                                  style="color: Lightgrey"
                                />
                              </span>
                              <span v-if="crit.level == 2">
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star"
                                  style="color: Lightgrey"
                                />

                                <i
                                  class="fas fa-crown star"
                                  style="color: Lightgrey"
                                />
                              </span>
                              <span v-if="crit.level == 1">
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star"
                                  style="color: Lightgrey"
                                />
                                <i
                                  class="fas fa-star star"
                                  style="color: Lightgrey"
                                />

                                <i
                                  class="fas fa-crown star"
                                  style="color: Lightgrey"
                                />
                              </span>
                            </div>
                            <div
                              v-if="crit.require_files && !hide"
                              class="text-primary"
                            >
                              <i class="fas fa-file-text"></i> Doc. requis
                            </div>

                            <div v-if="crit.optional">Critère optionnel</div>

                            <div v-if="!hide">
                              Valeurs possibles :
                              <p v-if="crit.answer === 'yesno'">OUI/NON</p>
                              <p v-else-if="crit.answer === 'range'">
                                COTE de 1 à 4
                              </p>
                              <p v-else>Erreur!</p>
                            </div>
                          </div>
                        </div>
                        <!-- SOUS-CRITERES DANS LES CRITERES -->
                        <div v-else style="margin-left: 10px">
                          <div style="font-size: medium">
                            <div class="d-flex">
                              <div class="col-10">
                                <p class="text-black">
                                  <i
                                    ><b>{{ crit.refernce }}</b></i
                                  >
                                  - <b>{{ crit.question }}</b>
                                </p>
                                <!-- AJOUT SAMI LE 26-05-22 -->
                                <div
                                  style="
                                    border: 2px solid #ff7700;
                                    border-radius: 10px;
                                  "
                                  class="p-3"
                                  v-if="crit.requirements"
                                >
                                  <span
                                    class="font-weight-bold"
                                    style="color: #ff7700"
                                    >{{ $t("buttons.requirements") }}</span
                                  >
                                  <nl2br
                                    tag="p"
                                    :text="crit.requirements"
                                    class-name="pt-2 pl-3"
                                  />
                                </div>
                                <div
                                  v-if="crit.explanations"
                                  style="
                                    border: 2px solid #2f48c5;
                                    border-radius: 10px;
                                  "
                                  class="p-3 mt-3"
                                >
                                  <span class="text-primary font-weight-bold">{{
                                    $t("buttons.explanations")
                                  }}</span>
                                  <nl2br
                                    tag="p"
                                    :text="crit.explanations"
                                    class-name="pt-2 pl-3"
                                  />
                                </div>
                              </div>

                              <!-- FIN AJOUT SAMI LE 26-05-22 -->
                              <div class="text-center col-2">
                                <div v-if="crit.critical" class="text-danger">
                                  <i class="fas fa-exclamation-circle" />
                                  {{ $t("critical") }}
                                </div>
                                <div class="text-center" v-if="!hide">
                                  <span v-if="crit.level == 4">
                                    <i
                                      class="fas fa-star star-gold star"
                                      style="color: gold"
                                    />
                                    <i
                                      class="fas fa-star star-gold star"
                                      style="color: gold"
                                    />
                                    <i
                                      class="fas fa-star star-gold star"
                                      style="color: gold"
                                    />

                                    <i
                                      class="fas fa-crown star-gold star"
                                      style="color: gold"
                                    />
                                  </span>
                                  <span v-if="crit.level == 3">
                                    <i
                                      class="fas fa-star star-gold star"
                                      style="color: gold"
                                    />
                                    <i
                                      class="fas fa-star star-gold star"
                                      style="color: gold"
                                    />
                                    <i
                                      class="fas fa-star star-gold star"
                                      style="color: gold"
                                    />

                                    <i
                                      class="fas fa-crown star"
                                      style="color: Lightgrey"
                                    />
                                  </span>
                                  <span v-if="crit.level == 2">
                                    <i
                                      class="fas fa-star star-gold star"
                                      style="color: gold"
                                    />
                                    <i
                                      class="fas fa-star star-gold star"
                                      style="color: gold"
                                    />
                                    <i
                                      class="fas fa-star star"
                                      style="color: Lightgrey"
                                    />

                                    <i
                                      class="fas fa-crown star"
                                      style="color: Lightgrey"
                                    />
                                  </span>
                                  <span v-if="crit.level == 1">
                                    <i
                                      class="fas fa-star star-gold star"
                                      style="color: gold"
                                    />
                                    <i
                                      class="fas fa-star star"
                                      style="color: Lightgrey"
                                    />
                                    <i
                                      class="fas fa-star star"
                                      style="color: Lightgrey"
                                    />

                                    <i
                                      class="fas fa-crown star"
                                      style="color: Lightgrey"
                                    />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <!-- BOUCLE SUR LES SOUS-CRITERES DU CRITERE -->
                            <div
                              v-for="subcrit of crit.subcriteria"
                              :key="subcrit.id"
                              class="d-flex sub-crit"
                            >
                              <div class="col-lg-10 col-md-10">
                                <p class="text-black">
                                  <i
                                    ><b>{{ subcrit.refernce }}</b></i
                                  >
                                  - <b>{{ subcrit.question }}</b>
                                </p>
                                <div
                                  style="
                                    border: 2px solid #ff7700;
                                    border-radius: 10px;
                                  "
                                  class="p-3"
                                  v-if="subcrit.requirements"
                                >
                                  <span
                                    class="font-weight-bold"
                                    style="color: #ff7700"
                                    >{{ $t("buttons.requirements") }}</span
                                  >
                                  <nl2br
                                    tag="p"
                                    :text="subcrit.requirements"
                                    class-name="pt-2 pl-3"
                                  />
                                </div>
                                <div
                                  v-if="subcrit.explanations"
                                  style="
                                    border: 2px solid #2f48c5;
                                    border-radius: 10px;
                                  "
                                  class="p-3 mt-3"
                                >
                                  <span class="text-primary font-weight-bold">{{
                                    $t("buttons.explanations")
                                  }}</span>
                                  <nl2br
                                    tag="p"
                                    :text="subcrit.explanations"
                                    class-name="pt-2 pl-3"
                                  />
                                </div>
                              </div>
                              <div
                                class="col-lg-2 col-md-2 text-center criteria-options"
                              >
                                <div
                                  v-if="subcrit.critical"
                                  class="text-danger"
                                >
                                  <i class="fas fa-exclamation-circle" />
                                  {{ $t("critical") }}
                                </div>
                                <div
                                  v-if="subcrit.require_files && !hide"
                                  class="text-primary"
                                >
                                  <i class="fas fa-file-text"></i> Doc. requis
                                </div>

                                <div v-if="subcrit.optional && !hide">
                                  Critère optionnel
                                </div>
                                <div v-if="!hide">
                                  Valeurs possibles :
                                  <p v-if="subcrit.answer === 'yesno'">
                                    OUI/NON
                                  </p>
                                  <p v-else-if="subcrit.answer === 'range'">
                                    COTE de 1 à 4
                                  </p>
                                  <p v-else>Erreur!</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>

                <!-- CHAPITRES SANS SECTIONS -->
                <section
                  class="pdf-item"
                  v-for="(crit, i) of chapter.criteria"
                  :key="crit.id"
                >
                  <!-- FILTRES PAR NIVEAUX -->
                  <div v-if="levelFilter.length">
                    <div
                      v-if="filterCondition(crit.level)"
                      class="p-3 crit-outside"
                    >
                      <hr v-if="i >= 1" />
                      <!-- PAS DE SOUS-CRITERES DANS LES CRITERES -->
                      <div class="d-flex" v-if="!crit.subcriteria.length">
                        <div
                          class="col-lg-10 col-md-10"
                          style="font-size: medium"
                        >
                          <p class="text-black">
                            <i
                              ><b>{{ crit.refernce }}</b></i
                            >
                            - <b>{{ crit.question }}</b>
                          </p>
                          <div
                            style="
                              border: 2px solid #ff7700;
                              border-radius: 10px;
                            "
                            class="p-3"
                            v-if="crit.requirements"
                          >
                            <span
                              class="font-weight-bold"
                              style="color: #ff7700"
                              >{{ $t("buttons.requirements") }}</span
                            >
                            <nl2br
                              class-name="pt-2 pl-3"
                              tag="p"
                              :text="crit.requirements"
                            />
                          </div>
                          <div
                            v-if="crit.explanations"
                            style="
                              border: 2px solid #2f48c5;
                              border-radius: 10px;
                            "
                            class="p-3 mt-3"
                          >
                            <span class="text-primary font-weight-bold">{{
                              $t("buttons.explanations")
                            }}</span>
                            <p class="pt-2 pl-3">
                              {{ crit.explanations }}
                            </p>
                          </div>
                        </div>
                        <div
                          class="col-lg-2 col-md-2 text-center criteria-options"
                        >
                          <div v-if="crit.critical" class="text-danger">
                            <i class="fas fa-exclamation-circle" />
                            {{ $t("critical") }}
                          </div>
                          <div v-if="!hide" class="text-center mt-3">
                            <span v-if="crit.level == 4">
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />

                              <i
                                class="fas fa-crown star-gold star"
                                style="color: gold"
                              />
                            </span>
                            <span v-if="crit.level == 3">
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />

                              <i
                                class="fas fa-crown star"
                                style="color: Lightgrey"
                              />
                            </span>
                            <span v-if="crit.level == 2">
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star"
                                style="color: Lightgrey"
                              />

                              <i
                                class="fas fa-crown star"
                                style="color: Lightgrey"
                              />
                            </span>
                            <span v-if="crit.level == 1">
                              <i
                                class="fas fa-star star-gold star"
                                style="color: gold"
                              />
                              <i
                                class="fas fa-star star"
                                style="color: Lightgrey"
                              />
                              <i
                                class="fas fa-star star"
                                style="color: Lightgrey"
                              />

                              <i
                                class="fas fa-crown star"
                                style="color: Lightgrey"
                              />
                            </span>
                          </div>
                          <div
                            v-if="crit.require_files && !hide"
                            class="text-primary"
                          >
                            <i class="fas fa-file-text"></i> Doc. requis
                          </div>
                          <div v-if="crit.optional">Critère optionnel</div>

                          <div v-if="!hide">
                            Valeurs possibles :
                            <p v-if="crit.answer === 'yesno'">OUI/NON</p>
                            <p v-else-if="crit.answer === 'range'">
                              COTE de 1 à 4
                            </p>
                            <p v-else>Erreur!</p>
                          </div>
                        </div>
                      </div>
                      <!-- SOUS-CRITERES DANS LES CRITERES -->
                      <div v-else style="margin-left: 20px">
                        <div style="font-size: medium">
                          <div class="d-flex">
                            <div class="col-10">
                              <p class="text-black">
                                <i
                                  ><b>{{ crit.refernce }}</b></i
                                >
                                - <b>{{ crit.question }}</b>
                              </p>
                              <!-- AJOUT SAMI LE 26-05-22 -->
                              <div
                                style="
                                  border: 2px solid #ff7700;
                                  border-radius: 10px;
                                "
                                class="p-3"
                                v-if="crit.requirements"
                              >
                                <span
                                  class="font-weight-bold"
                                  style="color: #ff7700"
                                  >{{ $t("buttons.requirements") }}</span
                                >
                                <nl2br
                                  tag="p"
                                  :text="crit.requirements"
                                  class-name="pt-2 pl-3"
                                />
                              </div>
                              <div
                                v-if="crit.explanations"
                                style="
                                  border: 2px solid #2f48c5;
                                  border-radius: 10px;
                                "
                                class="p-3 mt-3"
                              >
                                <span class="text-primary font-weight-bold">{{
                                  $t("buttons.explanations")
                                }}</span>
                                <nl2br
                                  tag="p"
                                  :text="crit.explanations"
                                  class-name="pt-2 pl-3"
                                />
                              </div>
                            </div>

                            <!-- FIN AJOUT SAMI LE 26-05-22 -->
                            <div class="col-2 text-center">
                              <div v-if="crit.critical" class="text-danger">
                                <i class="fas fa-exclamation-circle" />
                                {{ $t("critical") }}
                              </div>
                              <div class="text-center mt-3" v-if="!hide">
                                <span v-if="crit.level == 4">
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />

                                  <i
                                    class="fas fa-crown star-gold star"
                                    style="color: gold"
                                  />
                                </span>
                                <span v-if="crit.level == 3">
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />

                                  <i
                                    class="fas fa-crown star"
                                    style="color: Lightgrey"
                                  />
                                </span>
                                <span v-if="crit.level == 2">
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star"
                                    style="color: Lightgrey"
                                  />

                                  <i
                                    class="fas fa-crown star"
                                    style="color: Lightgrey"
                                  />
                                </span>
                                <span v-if="crit.level == 1">
                                  <i
                                    class="fas fa-star star-gold star"
                                    style="color: gold"
                                  />
                                  <i
                                    class="fas fa-star star"
                                    style="color: Lightgrey"
                                  />
                                  <i
                                    class="fas fa-star star"
                                    style="color: Lightgrey"
                                  />

                                  <i
                                    class="fas fa-crown star"
                                    style="color: Lightgrey"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                          <!-- BOOUCLE SUR LES SOUS-CRITERES -->
                          <div
                            v-for="subcrit of crit.subcriteria"
                            :key="subcrit.id"
                            class="d-flex sub-crit"
                          >
                            <div class="col-lg-10 col-md-10">
                              <p class="text-black">
                                <i
                                  ><b>{{ subcrit.refernce }}</b></i
                                >
                                - <b>{{ subcrit.question }}</b>
                              </p>
                              <div
                                style="
                                  border: 2px solid #ff7700;
                                  border-radius: 10px;
                                "
                                class="p-3"
                                v-if="subcrit.requirements"
                              >
                                <span
                                  class="font-weight-bold"
                                  style="color: #ff7700"
                                  >{{ $t("buttons.requirements") }}</span
                                >
                                <nl2br
                                  tag="p"
                                  :text="subcrit.requirements"
                                  class-name="pt-2 pl-3"
                                />
                              </div>
                              <div
                                v-if="subcrit.explanations"
                                style="
                                  border: 2px solid #2f48c5;
                                  border-radius: 10px;
                                "
                                class="p-3 mt-3"
                              >
                                <span class="text-primary font-weight-bold">{{
                                  $t("buttons.explanations")
                                }}</span>
                                <p class="pt-2 pl-3">
                                  {{ subcrit.explanations }}
                                </p>
                              </div>
                            </div>
                            <div
                              class="col-lg-2 col-md-2 text-center criteria-options"
                            >
                              <div v-if="subcrit.critical" class="text-danger">
                                <i class="fas fa-exclamation-circle" />
                                {{ $t("critical") }}
                              </div>

                              <!-- AJOUTE SAMI 26-05-22 -->
                              <div
                                v-if="subcrit.require_files && !hide"
                                class="text-primary"
                              >
                                <i class="fas fa-file-text"></i> Doc. requis
                              </div>

                              <div v-if="subcrit.optional && !hide">
                                Critère optionnel
                              </div>

                              <div v-if="!hide">
                                Valeurs possibles :
                                <p v-if="subcrit.answer === 'yesno'">OUI/NON</p>
                                <p v-else-if="subcrit.answer === 'range'">
                                  COTE de 1 à 4
                                </p>
                                <p v-else>Erreur!</p>
                              </div>

                              <!-- FIN AJOUTE SAMI 26-05-22 -->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- PAS FILTRES PAR NIVEAUX -->
                  <div v-else>
                    <hr v-if="i >= 1" />
                    <!-- PAS DE SOUS-CRITERES DANS LES CRITERES -->
                    <div v-if="!crit.subcriteria.length" class="d-flex">
                      <div
                        class="col-lg-10 col-md-10"
                        style="font-size: medium"
                      >
                        <p class="text-black">
                          <i
                            ><b>{{ crit.refernce }}</b></i
                          >
                          - <b>{{ crit.question }}</b>
                        </p>
                        <div
                          style="border: 2px solid #ff7700; border-radius: 10px"
                          class="p-3"
                          v-if="crit.requirements"
                        >
                          <span
                            class="font-weight-bold"
                            style="color: #ff7700"
                            >{{ $t("buttons.requirements") }}</span
                          >
                          <nl2br
                            class-name="pt-2 pl-3"
                            tag="p"
                            :text="crit.requirements"
                          />
                        </div>
                        <div
                          v-if="crit.explanations"
                          style="border: 2px solid #2f48c5; border-radius: 10px"
                          class="p-3 mt-3"
                        >
                          <span class="text-primary font-weight-bold">{{
                            $t("buttons.explanations")
                          }}</span>
                          <p class="pt-2 pl-3">
                            {{ crit.explanations }}
                          </p>
                        </div>
                      </div>
                      <div
                        class="col-lg-2 col-md-2 text-center criteria-options"
                      >
                        <div v-if="crit.critical" class="text-danger">
                          <i class="fas fa-exclamation-circle" />
                          {{ $t("critical") }}
                        </div>
                        <div class="text-center mt-3" v-if="!hide">
                          <span v-if="crit.level == 4">
                            <i
                              class="fas fa-star star-gold star"
                              style="color: gold"
                            />
                            <i
                              class="fas fa-star star-gold star"
                              style="color: gold"
                            />
                            <i
                              class="fas fa-star star-gold star"
                              style="color: gold"
                            />

                            <i
                              class="fas fa-crown star-gold star"
                              style="color: gold"
                            />
                          </span>
                          <span v-if="crit.level == 3">
                            <i
                              class="fas fa-star star-gold star"
                              style="color: gold"
                            />
                            <i
                              class="fas fa-star star-gold star"
                              style="color: gold"
                            />
                            <i
                              class="fas fa-star star-gold star"
                              style="color: gold"
                            />

                            <i
                              class="fas fa-crown star"
                              style="color: Lightgrey"
                            />
                          </span>
                          <span v-if="crit.level == 2">
                            <i
                              class="fas fa-star star-gold star"
                              style="color: gold"
                            />
                            <i
                              class="fas fa-star star-gold star"
                              style="color: gold"
                            />
                            <i
                              class="fas fa-star star"
                              style="color: Lightgrey"
                            />

                            <i
                              class="fas fa-crown star"
                              style="color: Lightgrey"
                            />
                          </span>
                          <span v-if="crit.level == 1">
                            <i
                              class="fas fa-star star-gold star"
                              style="color: gold"
                            />
                            <i
                              class="fas fa-star star"
                              style="color: Lightgrey"
                            />
                            <i
                              class="fas fa-star star"
                              style="color: Lightgrey"
                            />

                            <i
                              class="fas fa-crown star"
                              style="color: Lightgrey"
                            />
                          </span>
                        </div>
                        <!-- AJOUTE SAMI 26-05-22 -->
                        <div
                          v-if="crit.require_files && !hide"
                          class="text-primary"
                        >
                          <i class="fas fa-file-text"></i> Doc. requis
                        </div>
                        <div v-if="crit.optional">Critère optionnel</div>

                        <div v-if="!hide">
                          Valeurs possibles :
                          <p v-if="crit.answer === 'yesno'">OUI/NON</p>
                          <p v-else-if="crit.answer === 'range'">
                            COTE de 1 à 4
                          </p>
                          <p v-else>Erreur!</p>
                        </div>

                        <!-- FIN AJOUTE SAMI 26-05-22 -->
                      </div>
                    </div>
                    <!-- SOUS-CRITERES DANS LES CRITERES -->
                    <div v-else style="margin-left: 20px">
                      <div style="font-size: medium">
                        <div class="d-flex">
                          <div class="col-10">
                            <p class="text-black">
                              <i
                                ><b>{{ crit.refernce }}</b></i
                              >
                              - <b>{{ crit.question }}</b>
                            </p>

                            <!-- AJOUT SAMI LE 26-05-22 -->
                            <div
                              style="
                                border: 2px solid #ff7700;
                                border-radius: 10px;
                              "
                              class="p-3"
                              v-if="crit.requirements"
                            >
                              <span
                                class="font-weight-bold"
                                style="color: #ff7700"
                                >{{ $t("buttons.requirements") }}</span
                              >
                              <nl2br
                                tag="p"
                                :text="crit.requirements"
                                class-name="pt-2 pl-3"
                              />
                            </div>
                            <div
                              v-if="crit.explanations"
                              style="
                                border: 2px solid #2f48c5;
                                border-radius: 10px;
                              "
                              class="p-3 mt-3"
                            >
                              <span class="text-primary font-weight-bold">{{
                                $t("buttons.explanations")
                              }}</span>
                              <nl2br
                                tag="p"
                                :text="crit.explanations"
                                class-name="pt-2 pl-3"
                              />
                            </div>
                          </div>

                          <!-- FIN AJOUT SAMI LE 26-05-22 -->
                          <div class="col-2 text-center">
                            <div v-if="crit.critical" class="text-danger">
                              <i class="fas fa-exclamation-circle" />
                              {{ $t("critical") }}
                            </div>
                            <div class="text-center mt-3" v-if="!hide">
                              <span v-if="crit.level == 4">
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />

                                <i
                                  class="fas fa-crown star-gold star"
                                  style="color: gold"
                                />
                              </span>
                              <span v-if="crit.level == 3">
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />

                                <i class="fas fa-crown star" />
                              </span>
                              <span v-if="crit.level == 2">
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i class="fas fa-star star" />

                                <i class="fas fa-crown star" />
                              </span>
                              <span v-if="crit.level == 1">
                                <i
                                  class="fas fa-star star-gold star"
                                  style="color: gold"
                                />
                                <i
                                  class="fas fa-star star"
                                  style="color: Lightgrey"
                                />
                                <i
                                  class="fas fa-star star"
                                  style="color: Lightgrey"
                                />

                                <i
                                  class="fas fa-crown star"
                                  style="color: Lightgrey"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <!-- BOUCLE SUR LES SOUS-CRITERES -->
                        <div
                          v-for="subcrit of crit.subcriteria"
                          :key="subcrit.id"
                          class="d-flex sub-crit"
                        >
                          <div class="col-lg-10 col-md-10">
                            <p class="text-black">
                              <i
                                ><b>{{ subcrit.refernce }}</b></i
                              >
                              - <b>{{ subcrit.question }}</b>
                            </p>
                            <div
                              style="
                                border: 2px solid #ff7700;
                                border-radius: 10px;
                              "
                              class="p-3"
                              v-if="subcrit.requirements"
                            >
                              <span
                                class="font-weight-bold"
                                style="color: #ff7700"
                                >{{ $t("buttons.requirements") }}</span
                              >
                              <nl2br
                                tag="p"
                                :text="subcrit.requirements"
                                class-name="pt-2 pl-3"
                              />
                            </div>
                            <div
                              v-if="subcrit.explanations"
                              style="
                                border: 2px solid #2f48c5;
                                border-radius: 10px;
                              "
                              class="p-3 mt-3"
                            >
                              <span class="text-primary font-weight-bold">{{
                                $t("buttons.explanations")
                              }}</span>
                              <p class="pt-2 pl-3">
                                {{ subcrit.explanations }}
                              </p>
                            </div>
                          </div>
                          <div
                            class="col-lg-2 col-md-2 text-center criteria-options"
                          >
                            <div v-if="subcrit.critical" class="text-danger">
                              <i class="fas fa-exclamation-circle" />
                              {{ $t("critical") }}
                            </div>

                            <!-- AJOUTE SAMI 26-05-22 -->
                            <div
                              v-if="subcrit.require_files && !hide"
                              class="text-primary"
                            >
                              <i class="fas fa-file-text"></i> Doc. requis
                            </div>

                            <div v-if="subcrit.optional && !hide">
                              Critère optionnel
                            </div>
                            <div v-if="!hide">
                              Valeurs possibles :
                              <p v-if="subcrit.answer === 'yesno'">OUI/NON</p>
                              <p v-else-if="subcrit.answer === 'range'">
                                COTE de 1 à 4
                              </p>
                              <p v-else>Erreur!</p>
                            </div>

                            <!-- FIN AJOUTE SAMI 26-05-22 -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <!-- <div class="html2pdf__page-break" /> -->
            </div>
          </div>
          <!-- </vue-html2pdf> -->
        </div>
      </iq-card>
      <b-button v-if="displayUpBtn" id="btn-back-to-top" @click="scrollTop">
        <i style="margin-right: 0" class="fas fa-arrow-up"></i>
      </b-button>
    </b-container>
    <Loader v-if="!done" />
  </div>
</template>

<style>
#btn-back-to-top {
  position: fixed;
  bottom: 20px;
  right: 35px;
  z-index: 99;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: x-large;
  background: #ff7700;
  width: 60px;
  height: 60px;
  border-radius: 60px;
}
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10000;
}
.section {
  border-left: 2px solid rgb(187, 187, 187);
  margin-left: 10px;
}
.crit {
  border-left: 2px solid rgb(187, 187, 187);
  margin-left: 30px;
  min-height: 130px;
}
.star {
  margin-left: 7px;
}

.star-gold {
  color: gold;
}

.crit-outside {
  border-left: 2px solid rgb(187, 187, 187);

  margin-left: 10px;
  min-height: 130px;
}

.summary-click:hover {
  background-color: #2f48c5 !important;
  border-radius: 5px;

  padding-top: 10px;
  padding-bottom: 10px;
}

.summary-click:hover h6,
.summary-click:hover h5 {
  color: white !important;
}

.summary-click {
  cursor: pointer;
  transition-duration: 300ms;
  padding-left: 10px;
  padding-right: 10px;
}
</style>
