<script>
import ApiService from "../../Utils/axios";
import Table from "./EditableTable";
import TableManager from "./EditableTableManager";
import TableStandard from "./EditableTableStandard";
import Loader from "../../components/xray/Loader";
import { mapGetters } from "vuex";
import ListSimpleManager from "./ListSimpleManager";
import ListSimpleStandard from "./ListSimpleStandard";
import ListSimple from "./ListSimple";
export default {
  components: {
    Table,
    ListSimpleManager,
    ListSimpleStandard,
    Loader,
    ListSimple,
  },
  computed: {
    ...mapGetters({ user: "Auth/getCurrentUser" }),
  },
  data() {
    return {
      list: [],
      services: [],
      done: false,
      columns: [
        { label: "Intitulé", key: "title", class: "text-left", sortable: true },
        {
          label: "Etablissement",
          key: "institution",
          class: "text-left",
          sortable: true,
        },
        {
          label: "Services",
          key: "services",
          class: "text-left",
          sortable: true,
        },
        { label: "Niveau", key: "level", class: "text-left", sortable: true },
        { label: "Action", key: "action", class: "text-center" },
      ],
      columnsMgr: [
        { label: "Intitulé", key: "title", class: "text-left" },
        { label: "Services", key: "services", class: "text-left" },
        { label: "Niveau", key: "level", class: "text-left" },
        { label: "Action", key: "action", class: "text-center" },
      ],
      columnsStd: [
        { label: "Intitulé", key: "title", class: "text-left" },
        { label: "Niveau", key: "level", class: "text-left" },
        { label: "Action", key: "action", class: "text-center" },
      ],
    };
  },
  beforeMount() {
    this.fetchList();
  },
  mounted() {
    document.title = `${this.$t("pageTitles.surveys")} - Auditor`;
    console.log(process.env);
  },
  methods: {
    fetchList() {
      this.done = false;
      new ApiService()
        .get("/api/survey")
        .then((res) => {
          this.list = res.data.data;
          this.done = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<template>
  <div>
    <Table
      v-if="
        done &&
        list.length &&
        (user.roles.includes('ADMIN') || user.roles.includes('SUPER_ADMIN'))
      "
      :addBtn="{
        text: $t('buttons.add'),
        link: '/survey/create',
      }"
      :tableTitle="$t('pageTitles.surveys')"
      :rows="list"
      :cols="columns"
      :fetchList="fetchList"
    />
    <!-- <TableManager
      v-if="done && list.length && !user.roles.includes('ADMIN') && !user.roles.includes('SUPER_ADMIN') && user.roles.includes('MANAGER')"
      :addBtn="{
        text:: $t('buttons.add') 'Ajouter un questionnaire',
        link: '/survey/create'
      }"
      tableTitle="Liste des questionnaires"
      :rows="list"
      :cols="columnsMgr"
      :fetchList="fetchList"
    /> -->
    <!-- <TableStandard
      v-if="done && list.length && !user.roles.includes('ADMIN') && !user.roles.includes('SUPER_ADMIN') && user.roles.includes('STANDARD')"
      :addBtn="{
        text:: $t('buttons.add') 'Ajouter un questionnaire',
        link: '/survey/create'
      }"
      tableTitle="Liste des questionnaires"
      :rows="list"
      :cols="columnsStd"
      :fetchList="fetchList"
    /> -->
    <ListSimpleManager
      :rows="list"
      :cols="columns"
      v-if="
        done &&
        list.length &&
        !user.roles.includes('ADMIN') &&
        !user.roles.includes('SUPER_ADMIN') &&
        user.roles.includes('MANAGER')
      "
    />
    <ListSimpleStandard
      :rows="list"
      :cols="columns"
      v-if="
        done &&
        list.length &&
        !user.roles.includes('ADMIN') &&
        !user.roles.includes('SUPER_ADMIN') &&
        user.roles.includes('STANDARD')
      "
    />
    <ListSimple
      :rows="list"
      :cols="columns"
      v-if="
        done &&
        list.length &&
        !user.roles.includes('ADMIN') &&
        !user.roles.includes('SUPER_ADMIN') &&
        user.roles.includes('GUEST')
      "
    />
    <b-container
      v-if="done && !list.length"
      style="height: 75vh"
      class="text-center"
      fluid
      ><h5>Aucun questionnaire trouvé dans notre base de données.</h5>
      <b-button
        v-if="user.roles.includes('ADMIN')"
        class="mt-3"
        variant="primary"
        :to="'/' + $i18n.locale + '/survey/create'"
        ><i class="fas fa-plus"></i> {{ $t("buttons.add") }}
      </b-button>
    </b-container>
    <Loader v-if="!done" />
  </div>
</template>

<style>
.w-5 {
  max-width: 5%;
}
</style>
